import { Action, createAction } from './actions';

export namespace Actions {
    export const ReuseDemographicsShowType = 'REUSE_DEMOGRAPHICS_DIALOG_SHOW';
    export type ReuseDemographicsShowAction = Action<typeof ReuseDemographicsShowType, boolean>;

    export function showReuseDemographics(show: boolean): ReuseDemographicsShowAction {
        return createAction(ReuseDemographicsShowType, show);
    }

    // Export all of the actions from this file
    export type DemographicsActions = ReuseDemographicsShowAction;
}