import { AssessmentModel, AssessmentStatus } from '../models/assessment';
import { getLaunchAssessmentUrl } from '../services/candidate-service';
import { logErrorWithAlert } from './error';
import { CmsText } from './cms-text';

export function launchAssessment(assessment: AssessmentModel, siteLanguageId: number, text: CmsText): Promise<any> {
    // The assessment must be taken in it's currently set language, if not defined then must use the site language.
    let languageId = (assessment.languageId > 0) ? assessment.languageId : siteLanguageId;

    return getLaunchAssessmentUrl(assessment.assessmentId, languageId)
        .then(result => {
            if (result.status === AssessmentStatus.Completed) {
                logErrorWithAlert(text.get('canNotLaunchError', 'Cannot launch assessment'), 'Unable to launch assessment, assessment complete');

            } else if (!result.launchUrl) {
                logErrorWithAlert(text.get('canNotLaunchError', 'Cannot launch assessment'), 'Unable to launch assessment, no url found');
            } else {
                location.href = result.launchUrl;
            }
        })
        .catch(exception => {
            logErrorWithAlert(text.get('canNotLaunchError', 'Cannot launch assessment'), 'Error launching assessment', exception);
        });
}
