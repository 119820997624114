import React from "react";
import SessionTimeoutContainer from "../components/session-timeout-page/sessionTimeoutContainer";
import { EmptyObject } from "../misc/common";

export default class SessionTimeOutPage
  extends React.Component<EmptyObject, EmptyObject>
  implements React.ComponentLifecycle<EmptyObject, EmptyObject>
{
  render() {
    return <SessionTimeoutContainer />;
  }
}