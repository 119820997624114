import { createRoot } from 'react-dom/client';
import './app.css';

// Redux
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, Middleware, Store, AnyAction } from 'redux';
import { AppReducer } from './reducers/app';
import * as State from './reducers/state';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { logReduxError } from './misc/error';
import App from './app';
// @ts-ignore
import reduxCatch from 'redux-catch';

// Define middleware to be used with Redux
let middlewares: Middleware[] = [];

// Redux middleware to handle async actions
middlewares.push(thunk);
middlewares.push(reduxCatch(logReduxError));

// Add logging for development and QA environments only
if (process.env.NODE_ENV !== `production`) {
  let logger: Middleware = createLogger();
  middlewares.push(logger);
}

const store: Store<State.All> = createStore<State.All, AnyAction, unknown, unknown>(AppReducer, applyMiddleware(...middlewares));
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>);