import React from 'react';
import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';
import { logErrorWithAlert } from '../../misc/error';
import { eLearningModel } from '../../models/elearning';
import { launchKFLearningSite } from '../../services/elearning-service';
import { Actions } from '../../actions/redirect-warning';

interface ELearningContentOwnProps {
    /**
     * The report to launch.
     */
    content: eLearningModel;
}

interface ELearningContentStateProps {
    /**
     * Component Text
     */
    text: CmsText;
}

interface RedirectWarningDispatchProps {
    openRedirectWarning: (cb: () => void) => void;
}

type ELearningContentAllProps = ELearningContentOwnProps & ELearningContentStateProps & RedirectWarningDispatchProps;

/**
 * Renders an eLearning content links component that opens a new windows to display eLearning content from KF Learn.
 * @param props The properties to pass to the component
 */
export class ELearningContent extends React.Component<ELearningContentAllProps> {
    constructor(props: ELearningContentAllProps) {
        super(props);
        this.openLearningContent = this.openLearningContent.bind(this);
    }

    /**
     * Open a new window navigating to KF Learn
     * @param contentId The eLearning content to launch
     * @returns Promise to launch to new tab
     */
    openLearningContent(contentId: number): () => Promise<any> {
        return (): Promise<any> => {
            return launchKFLearningSite(contentId)
                .then(url => {
                    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                    if (newWindow) { newWindow.opener = null; }
                })
                .catch(exception => {
                    logErrorWithAlert(this.props.text.get('canNotLaunchKFLearn', 'Cannot launch KF Learn'), 'Error launching kf learn', exception);
                });
        };
    }

    openRedirectWarningDialog(contentId: number): void {
        this.props.openRedirectWarning(this.openLearningContent(contentId));
    }

    render() {
        let contentId = this.props.content.contentId;
        let name = this.props.content.name;

        return (
            <div className="eLearning-contents-material" key={contentId}>
                <button className="eLearning-contents-button" aria-label={name} onClick={() => this.openRedirectWarningDialog(contentId)} >
                    <div className="eLearning-contents-text-container">
                        <span className="eLearning-contents-name">{name}</span>
                    </div>
                </button>
            </div>
        );
    }
}

export default Containers.createStateAndDispatchWithProps<ELearningContentStateProps, RedirectWarningDispatchProps, ELearningContentOwnProps>(
    ELearningContent,
    (state) => ({
        text: new CmsText(state.language.alltext, 'eLearning', 'dashboard.eLearning')
    }),
    (dispatch) => ({
        openRedirectWarning: (callback) => dispatch<any>(Actions.showRedirectWarning({
            show: true,
            onRedirectConfirmButtonClick: callback
        }))
    })
);