import DashboardPreviewContainer from '../components/dashboard/dashboardPreviewComponent';
import { RouterMetadata } from '../components/higher-order/with-router';

/**
 * Props for the DashboardPreviewPage, values passed in via React Router
 */
type DashboardPreviewPageProps = {
    /**
     * React Router metadata such as location, params, navigation
     */
    router: RouterMetadata
};


export default function DashboardPreview(props: DashboardPreviewPageProps) {
    return (
        <DashboardPreviewContainer brandingName={props.router.params.brandingName} />
    );
}