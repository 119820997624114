import { CmsText } from '../misc/cms-text';
import format from 'string-template';

/**
 * Enum matching the AssessmentType enum on the server.
 */
export enum AssessmentType {
    None = 0,
    Analytic = 1,
    ElementsNumerical = 2,
    Dimensions = 3,
    ElementsVerbal = 5,
    VerbalTrial = 6,
    NumericalTrial = 7,
    ElementsVerbalVerification = 8,
    ElementsNumericalVerification = 9,
    ElementsLogical = 10,
    ElementsLogicalVerification = 11,
    AspectsStyles = 12,
    MultiView = 13,
    Drives = 14,
    AspectsNumerical = 15,
    AspectsVerbal = 16,
    AspectsChecking = 17,
    AspectsJudgement = 18,
    Composite = 19,
    PlayerTest = 20,
    KF4D = 21,
    Kf4dJobProfile = 22,
    KF4DCompetencies = 23,
    Drivers = 24,
    Traits = 25,
    Experiences = 26,
    Preferences = 27,
    AbstractReasoning = 28, // Ravens,
    TechnicalSkillsInventory = 29,
    EntryLevelCompetency = 30,
    VitualRecruiter = 31,
    InclusiveLeaderSjt = 32
}

/**
 * Gets the name of an assessment.
 * @param assessmentModel The assessment model.
 * @param text The CMS text object to get text from
 * @returns The name of the assessment.
 */
export function getAssessmentName(assessmentModel: AssessmentModel, text: CmsText): string {
    switch (assessmentModel.type) {
        case AssessmentType.ElementsNumerical: 
        case AssessmentType.AspectsNumerical: 
            return text.get('description.elementsNumerical.title', 'Numerical Ability');
        case AssessmentType.ElementsVerbal: 
        case AssessmentType.AspectsVerbal: 
            return text.get('description.elementsVerbal.title', 'Verbal Ability');
        case AssessmentType.ElementsLogical: return text.get('description.elementsLogical.title', 'Logical Ability');
        case AssessmentType.AspectsChecking: return text.get('description.checking.title', 'Checking Ability');
        case AssessmentType.AspectsStyles: return text.get('description.aspectsStyles.title', 'Aspects Styles');
        case AssessmentType.Drives: return text.get('description.drives.title', 'Drives');
        case AssessmentType.KF4D: return text.get('description.kf4d.title', 'KF4D');
        case AssessmentType.Kf4dJobProfile: 
            return format(text.get('description.kf4dJobProfile.title', 'KF4D Job Profile ({jobProfileName})'), { 'jobProfileName': assessmentModel.jobProfileName });
        case AssessmentType.Dimensions: 
        case AssessmentType.KF4DCompetencies:
        case AssessmentType.EntryLevelCompetency:
            return text.get('description.kf4dCompetencies.title', 'Behavioral Competencies');
        case AssessmentType.Drivers: return text.get('description.drivers.title', 'Drivers');
        case AssessmentType.Traits: return text.get('description.traits.title', 'Traits');
        case AssessmentType.Experiences: return text.get('description.experiences.title', 'Experiences');
        case AssessmentType.Preferences: return text.get('description.preferences.title', 'Preferences');
        case AssessmentType.PlayerTest: return assessmentModel.name;
        case AssessmentType.AbstractReasoning: return text.get('description.abstractReasoning.title', 'Abstract Reasoning');
        case AssessmentType.TechnicalSkillsInventory: return text.get('description.technicalSkillsInventory.title', 'Technical Skills Inventory');
        case AssessmentType.VitualRecruiter: return text.get('description.virtualRecruiter.title', 'Virtual Recruiter');
        case AssessmentType.InclusiveLeaderSjt: return text.get('description.inclusiveLeaderSjt.title', 'Inclusive Leader Situational Judgmnent Test');
        default: return 'Unknown';
    }
}
/**
 * Gets the icon of an assessment.
 * @param type The type of assessment.
 * @returns The path to the assessment icon.
 */
export function getAssessmentIcon(type: AssessmentType): string {
    let imageName: string;
    let iconClassBase = 'assessment-container-icon' + ' dashboard-icon';
    switch (type) {
        case AssessmentType.ElementsNumerical: imageName = '-numerical'; break;
        case AssessmentType.Dimensions: imageName = '-dimensions'; break;
        case AssessmentType.ElementsVerbal: imageName = '-verbal'; break;
        case AssessmentType.ElementsLogical: imageName = '-logical'; break;
        case AssessmentType.AspectsStyles: imageName = '-aspects-styles'; break;
        case AssessmentType.Drives: imageName = '-drives'; break;
        case AssessmentType.AspectsNumerical: imageName = '-numerical'; break;
        case AssessmentType.AspectsVerbal: imageName = '-verbal'; break;
        case AssessmentType.AspectsChecking: imageName = '-checking'; break;
        case AssessmentType.KF4D: imageName = '-personality'; break;
        case AssessmentType.KF4DCompetencies: imageName = '-kf4dCompetencies'; break;
        case AssessmentType.EntryLevelCompetency: imageName = '-elca'; break;
        case AssessmentType.Traits: imageName = '-traits'; break;
        case AssessmentType.Drivers: imageName = '-drivers'; break;
        case AssessmentType.Experiences: imageName = '-experiences'; break;
        case AssessmentType.Preferences: imageName = '-preferences'; break;
        case AssessmentType.PlayerTest: imageName = '-sjt'; break;
        case AssessmentType.AbstractReasoning: imageName = '-abstract-reasoning'; break;
        default: imageName = '-personality'; break;
    }
    return iconClassBase + imageName;
}

/**
 * Determines if the assessment is timed.
 * @param type The type of assessment.
 */
export function isTimedAssessment(type: AssessmentType): boolean {
    return type === AssessmentType.ElementsLogical ||
        type === AssessmentType.ElementsLogicalVerification ||
        type === AssessmentType.ElementsNumerical ||
        type === AssessmentType.ElementsNumericalVerification ||
        type === AssessmentType.ElementsVerbal ||
        type === AssessmentType.ElementsVerbalVerification ||
        type === AssessmentType.AspectsNumerical ||
        type === AssessmentType.AspectsVerbal ||
        type === AssessmentType.AspectsChecking ||
        type === AssessmentType.AbstractReasoning;
}

/**
 * Enum matching the AssessmentStatusLegacy enum on the server.
 */
export enum AssessmentStatus {
    NotStarted = 0,
    InProgress = 1,
    Completed = -1
}

/**
 * Converts an AssessmentStatus into it's text name.
 * @param status The status to convert
 * @returns The name of this status
 */
export function getAssessmentStatusText(status: AssessmentStatus): string {
    switch (status) {
        case AssessmentStatus.Completed: return '-Completed';
        case AssessmentStatus.InProgress: return '-In Progress';
        default: return '-Not Started';
    }
}

/**
 * Enum matching the CompatabilityStatus enum on the server.
 */
export enum CompatabilityStatus {
    NotCompatible = 0,
    Compatible = 1,
    CompatibleWithWarning = 2,
    Undetermined = 3
}

/**
 * Model defining what an assessment contains.
 */
export interface AssessmentModel {
    /**
     * Gets the ID of this assessment.
     */
    assessmentId: number;

    /**
     * Gets the type of this assessment.
     */
    type: AssessmentType;

    /**
     * Gets the specific test ID of this assessment if Type is a generic test type.
     */
    subTestId: number;

    /**
     * Gets the current status of this assessment.
     */
    status: AssessmentStatus;

    /**
     * Determines if this assessment is enabled for use. This would be disabled, for example, if an ability test had exceeded the maximum number of attempts.
     */
    isEnabled: boolean;

    /**
     * Determines if this assessment is locked from further use if, for example, a project deadline has passed.
     */
    isLocked: boolean;

    /**
     * Gets the ID of the language to use, or zero if the candidate must choose.
     */
    languageId: number;

    /**
     * Gets the time the assessment was completed, only present if Status is AssessmentStatus.Completed.
     */
    completed?: string;

    /**
     * Determine if a mouse if required for this assessment.
     */
    needsMouse: boolean;

    /**
     * Determine if a notepad is required for this assessment.
     */
    needsNotepad: boolean;

    /**
     * Determine if a calculator is required for this assessment.
     */
    needsCalculator: boolean;

    /**
     * Determine is sound is required for this assessment.
     */
    needsSound: boolean;

    /**
     * Gets the exact or approximate length of time the assessment will take in minutes, depending on the test type.
     */
    timeLengthMinutes: number;

    /**
     * The extra time in minutes the candidate has been given to complete a timed assessment.
     */
    timeExtraMinutes: number;

    /**
     * Gets the compatability status of this assessment - whether this assessment can be completed on this device.
     */
    compatability: CompatabilityStatus;

    /**
     * Gets the list of language id this assessment is available in.
     */
    supportedLanguageIds: number[];

    /**
     * Gets the job profile name.
     */
    jobProfileName: string;

    /**
     * The name of this assesssment
     */
    name: string;

    /**
     * The description text of this assessment
     */
    description: string;
    
    /**
     * Is assessment available to reuse
     */
    reuseAssessmentAvailable: boolean;

    /**
     * Id of assessment to reuse
     */
    reuseAssessmentId: number | null;

    /**
     * Date of assessment to reuse
     */
    reuseAssessmentDateTime: string | null;

    /**
     * The latest deadline of Assessment's projects, when all assessment's projects deadlines expired
     */
    latestProjectDeadline?: string | null;
}

export interface LaunchAssessmentResult {
    assessmentId: number;

    launchUrl: string;

    status: AssessmentStatus;
}

/**
 * Interface for requesting an assessment reuse
 */
export interface AssessmentReuseRequest {
    /**
     * Assessment Id that needs to reuse the responses from a previous assessment
     */
    assessmentId: number;

    /**
     * Assessment Id that the responses need to be taken from
     */
    reuseAssessmentId: number;
}

/**
 * Interface for response from a assessment reuse request
 */
export interface AssessmentReuseResult {
    /**
     * Was the assessment successfully reused
     */
    success: boolean;

    /**
     * Any error message returned if the request failed
     */
    error?: string;
}