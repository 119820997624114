import * as Containers from '../redux-containers';
import Assessment from './assessment';
import { AssessmentModel, AssessmentStatus } from '../../models/assessment';
import { CmsText } from '../../misc/cms-text';
import { NoJsxElement, NullableElement } from '../../misc/common';
import AssessmentHeaderContainer from './assessment-header';
import classNames from 'classnames';

/**
 * State props for the Assessments component.
 */
export interface AssessmentsStateProps {
    /**
     * Gets the list of assessments for this candidate.
     */
    assessments: AssessmentModel[];

    /**
     * Text for the dashboard component.
     */
    dashboardText: CmsText;

    /**
     * Determine if the assessments area is enabled to allow the candidate to complete them or if there is some pre-work required, e.g. complete demographics, accept privacy policy ....
     */
    disabled: boolean;
}

/**
 * Renders the list of assessments for this candidate.
 * @param props The properties for this component.
 */
export const Assessments = (props: AssessmentsStateProps) => {
    if (props.assessments.length === 0) {
        return NoJsxElement;
    }

    const renderPendingAssessments = (): NullableElement => {
        if (props.assessments.filter(isAssessmentPending).length === 0) {
            return NoJsxElement;
        }

        return (
            <div id="start-of-content" className={'incomplete-assessments' + ' ' + classes}>
                <div className="incomplete-assessments-title">
                    <h2>{ props.dashboardText.get('banner.incompleteAssessment', 'Assessments to complete') }</h2>
                    <span>
                        <span 
                            className="incomplete-assessments-title-lock-icon dashboard-icon-lock" 
                            role="img" 
                            aria-label={ props.dashboardText.get('lockedAssessment.personalDetails', 'Please tell us more about yourself above first') }
                        />
                        <span className="incomplete-assessments-title-lock-text">
                            { props.dashboardText.get('lockedAssessment.personalDetails', 'Please tell us more about yourself above first') }
                        </span>
                    </span>
                </div>
                <div className="incomplete-assessments-container">
                    <table className={'assessments-table'} aria-describedby="table-description-incomplete-assessments">
                        <tbody>
                            <AssessmentHeaderContainer />
                            { pendingAssessments }
                        </tbody>
                    </table>
                    <span className={'aria-label-described'} id="table-description-incomplete-assessments">
                        { props.dashboardText.get('banner.tableAssessments', 'Table of Assessments') }
                    </span>
                </div>
            </div>
        );
    };

    const pendingAssessments = props.assessments.filter(isAssessmentPending).map(a => (
        <Assessment
            key={a.assessmentId.toString()}
            assessment={a}
            text={props.dashboardText}
        />));

    const completedAssessments = props.assessments.filter(isAssessmentCompleted).sort(a => a.type)
        .map(a => (
        <Assessment
            key={a.assessmentId.toString()}
            assessment={a}
            text={props.dashboardText}
        />));

    const expiredAssessments = props.assessments.filter(isAssessmentExpired).sort(a => a.type)
        .map(a => (
        <Assessment
            key={a.assessmentId.toString()}
            assessment={a}
            text={props.dashboardText}
        />));

    // Lock the assessments if there is any outstanding prep work.
    let classes = classNames({
        'incomplete-assessments-locked': props.disabled
    });

    return (
        <div>
            {renderPendingAssessments()}
            {completedAssessments.length > 0 && (
            <div>
                <div className="complete-assessments-title">
                    <h2>{props.dashboardText.get('banner.completedAssessment', 'Completed Assessment(s)')}</h2>
                </div>
                <div className="complete-assessments">
                    <table aria-describedby="table-description-complete-assessments" className="complete-assessments-table">
                        <tbody>
                            { completedAssessments }
                            { expiredAssessments }
                        </tbody>
                    </table>
                    <span className={'aria-label-described'} id="table-description-complete-assessments">
                        { props.dashboardText.get('banner.tableAssessments', 'Table of Assessments') }
                    </span>
                </div>
            </div>)}
        </div>
    );
};

function isAssessmentCompleted(a: AssessmentModel): boolean {
    return a.status === AssessmentStatus.Completed;
}

function isAssessmentPending(a: AssessmentModel): boolean {
    return a.status !== AssessmentStatus.Completed && !a.isLocked;
}

function isAssessmentExpired(a: AssessmentModel): boolean {
    return a.status !== AssessmentStatus.Completed && a.isLocked;
}

export default Containers.createStateOnly(
    Assessments,
    (state) => ({
        assessments: state.candidate.assessments!,
        dashboardText: new CmsText(state.language.alltext, 'dashboard', 'dashboard'),
        disabled: state.candidate.state!.showDemographics && !state.candidate.state!.completedDemographics
    })
);
