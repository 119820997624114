import { Action, createAction } from './actions';
import { ThunkAction } from 'redux-thunk';
import { Language } from '../reducers/state';
import { CmsService } from '../services/cms-service';
import { updateCandidateLanguage } from '../services/candidate-service';
import { refreshCandidateData } from './candidate';
import { ResponseError } from '../services/api-service';
import * as State from '../reducers/state';
import { AnyAction } from 'redux';

export namespace Actions {
    export const LanguageChangeSuccessType = 'LANGUAGE_CHANGE_Success';
    export const LanguageChangeErrorType = 'LANGUAGE_CHANGE_Error';
    export const FetchingSiteTextType = 'FETCHING_SITE_TEXT';
    export const SetLanguageIdType = 'SET_LANGUAGE_ID';
    export type LanguageChangeAction = Action<typeof LanguageChangeSuccessType, Language>;
    export type LanguageChangeErrorAction = Action<typeof LanguageChangeErrorType, ResponseError>;
    export type FetchingTextAction = Action<typeof FetchingSiteTextType, boolean>;
    export type SetLanguageIdAction = Action<typeof SetLanguageIdType, number>;

    function getLanguage(alltext: any, languageId: number): any {
        return (alltext.allLanguages).filter((l: any) => l.id === languageId)[0];
    }

    export function changeLanguageSuccess(newLanguageId: number, alltext: any, saveCandidateLanguage: boolean): LanguageChangeAction {
        // If selected language doesn't exist, use default language instead as this is what the get SiteText
        // request does when an invalid language is requested
        let selectedLanguage = getLanguage(alltext, newLanguageId) || getLanguage(alltext, CmsService.GetDefaultLanguage());

        CmsService.SetSiteLanguage(selectedLanguage.id);

        // Set selected language code in the session storage
        CmsService.SetSiteLanguageCode(selectedLanguage.code)

        if (saveCandidateLanguage) {
            updateCandidateLanguage(newLanguageId);
        }

        return createAction(LanguageChangeSuccessType, {
            languageId: selectedLanguage.id,
            alltext: alltext,
            fetchingText: false,
            isLoaded: true,
            code: selectedLanguage.code,
            isRightToLeft: selectedLanguage.isRightToLeft
        });
    }

    export function setLanguageId(newLanguageId: number): SetLanguageIdAction {
        return createAction(SetLanguageIdType, newLanguageId);
    }

    export function changeLanguageError(responseError: ResponseError) {
        return createAction(LanguageChangeErrorType, responseError);
    }

    export function fetchingSiteText(): FetchingTextAction {
        return createAction(FetchingSiteTextType, true);
    }

    export function changeLanguage(newLanguageId: number, saveCandidateLanguage: boolean = false): ThunkAction<void, State.All, unknown, AnyAction> {
        return (dispatch, getState) => {
            dispatch(fetchingSiteText());

            var state: State.All = getState();
            var brandingName: string | undefined = state.branding.hasDashboardManifest ? state.branding.name : undefined;

            CmsService.GetSiteText(newLanguageId, state.candidate.isPreviewMode, brandingName)
                .then(r => {
                    CmsService.SetSiteLanguage(newLanguageId);
                    if (saveCandidateLanguage) {
                        updateCandidateLanguage(newLanguageId)
                            .then(_ => dispatch<any>(refreshCandidateData()));
                    }
                    dispatch(changeLanguageSuccess(newLanguageId, r, saveCandidateLanguage));
                })
                .catch((error: ResponseError) => dispatch(changeLanguageError(error)));
        };
    }

    export type LanguageActions = LanguageChangeAction | LanguageChangeErrorAction | FetchingTextAction | SetLanguageIdAction;
}