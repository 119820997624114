import * as State from './state';
import { Actions } from '../actions/demographics';

/**
 * The default initial state
 */
export const demographicsInitial: State.Demographics = {
    showReuseDemographicsDialog: false
};

/**
 * Reducer for the demographics
 */
export const DemographicsReducer =
    (state: State.Demographics = demographicsInitial, action: Actions.DemographicsActions): State.Demographics => {
        switch (action.type) {
            case Actions.ReuseDemographicsShowType:
                return {
                    ...state,
                    showReuseDemographicsDialog: action.payload.valueOf()
                };
            default:
                return state;
        }
    };