import * as Containers from '../redux-containers';
import format from 'string-template';
import { CmsText } from '../../misc/cms-text';

/**
 * State props for the Banner component
 */
export interface BannerStateProps {
    /**
     * Gets the display name to show in the banner.
     */
    displayName: string;

    /**
     * CMS content
     */
    componentText: CmsText;
}

/**
 * Renders the banner of the dashboard, the area which welcomes the candidate by name.
 * @param props The properties to pass to the component
 */
export const Banner = (props: BannerStateProps) => (
    <h1 className="assessment-banner-title">{format(props.componentText.get('welcome', 'welcome'), { 'name': props.displayName })}</h1>
);

export default Containers.createStateOnly(
    Banner,
    (state) => ({
        displayName: state.candidate.personal!.displayName,
        componentText: new CmsText(state.language.alltext, 'Banner', 'dashboard.banner')
    })
);