import { Action, createAction } from './actions';

interface RedirectWarningActionPayload {
    show: boolean;
    onRedirectConfirmButtonClick?: () => void;
}

export namespace Actions {
    export const RedirectWarningShowType = 'REDIRECT_WARNING_DIALOG_SHOW';

    export type RedirectWarningShowAction = Action<typeof RedirectWarningShowType, RedirectWarningActionPayload>;

    export function showRedirectWarning(payload: RedirectWarningActionPayload): RedirectWarningShowAction {
        return createAction(RedirectWarningShowType, payload);
    }

    // Export all of the actions from this file
    export type RedirectWarningActions = RedirectWarningShowAction;
}