import AssessmentsContainer from '../../components/dashboard/assessments';
import BannerContainer from '../../components/dashboard/banner';
import OverallProgressContainer from '../../components/dashboard/overall-progress';
import DownloadReports from '../../components/dashboard/downloadReports';
import Demographics from '../../components/dashboard/demographics';
import ReportsPreparing from './reportsPreparing';
import ELearningContents from './eLearningContents';

export const DashboardPageContents = () => {

    return (
        <>
            <div className="assessment-banner">
                <div className="assessment-banner-background">
                    <div className="assessment-banner-upic">
                        <img
                            src="/images/profile-desktop.svg"
                            alt="Generic Avatar"
                            aria-hidden="true"
                        />
                    </div>
                </div>
                <BannerContainer />
                <OverallProgressContainer />
            </div>
            <div className="centered-layout">
                <ELearningContents />
                <ReportsPreparing />
                <DownloadReports />
                <Demographics />
                <AssessmentsContainer />
            </div>
        </>
    );

}