import React from 'react';
import ReactModal from 'react-modal';
import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';
import { EmptyObject } from '../../misc/common';
import { CmsService } from '../../services/cms-service';
import { Actions } from '../../actions/redirect-warning';

interface RedirectWarningModalStateProps {
    isOpen: boolean;
    allText: any;
    languageId: number;
    onConfirmCallback?: () => void;
}

interface RedirectWarningModalDispatchProps {
    close: () => void;
    open: () => void;
}

export type RedirectWarningModalProps = RedirectWarningModalStateProps & RedirectWarningModalDispatchProps;

class RedirectWarningModal extends React.Component<RedirectWarningModalProps, EmptyObject> {
    private buttonRef = React.createRef<HTMLButtonElement>();
    
    constructor(props: RedirectWarningModalProps) {
        super(props);
    }

    public componentDidMount() {
        ReactModal.setAppElement('body');
    }

    public render() {
        const cmsText: CmsText = new CmsText(this.props.allText, 'Microlearning', 'dashboard.microlearning');
        const isOpen: boolean = !!this.props.isOpen;

        let title: string = cmsText.get('title', 'Microlearning');

        let body: string = cmsText.get('body', 'By clicking this link you will be taken to a separate portal to access the microlearning. '
            + 'No part of the content on this portal may be reproduced or transmitted in any form or by any means, electronic or mechanical,'
            + ' including photocopying, recording, or any information storage or retrieval system, without permission in writing from Korn Ferry.'
            + 'Exceptions, such as handout masters, are clearly marked.');

        const continueButton: string = cmsText.get('continue', 'Continue');

        return (
            <ReactModal
                isOpen={isOpen}
                className={CmsService.GetSiteFontStyle() + ' redirect-modal'}
                overlayClassName="modal-overlay"
                contentLabel={title + ' ' + body}
                ariaHideApp={false}
                role="alert"
            >
                <div className="modal-header" aria-label={title}>{title}</div>
                <div className="modal-body" aria-label={body}>{body}</div>
                <div className="modal-footer">
                    <button 
                        onClick={this.handleClose} 
                        className="modal-button" 
                        ref={this.buttonRef} 
                        aria-label={continueButton}>
                        {continueButton}
                    </button>
                </div>
            </ReactModal>
        );
    }

    private handleClose = () => {
        if (this.props.onConfirmCallback) {
            this.props.onConfirmCallback();
        }
        this.props.close();
    }
}

export default Containers.createStateAndDispatchWithProps<RedirectWarningModalStateProps, RedirectWarningModalDispatchProps, EmptyObject>(
    RedirectWarningModal,
    (state) => ({
        isOpen: state.redirectWarning.showRedirectWarningModal,
        onConfirmCallback: state.redirectWarning.onRedirectConfirmButtonClick,
        allText: state.language.alltext,
        languageId: state.language.languageId
    }),
    (dispatch) => ({
        close: () => dispatch<any>(Actions.showRedirectWarning({show: false})),
        open: () => dispatch<any>(Actions.showRedirectWarning({show: true}))
    })
);
