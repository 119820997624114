import React from 'react';
import SanctionedContainer from '../components/sanctioned/sanctioned-container';
import { EmptyObject } from '../misc/common';

/**
 * Method to return elements for sanctioned page
 */
export default class SanctionedPage extends React.Component<EmptyObject, EmptyObject> implements React.ComponentLifecycle<EmptyObject, EmptyObject> {
    render() {
        return <SanctionedContainer />;
    }
}