import { Action, createAction } from './actions';

export namespace Actions {
    export const SetTimeoutMinutesType = 'SET_TIMEOUT_MINUTES';
    export const SessionTimeoutWarningShowType = 'SESSION_TIMEOUT_WARNING_SHOW';

    export type SetTimeoutMinutesAction = Action<typeof SetTimeoutMinutesType, { timeoutMinutes: number }>;
    export type SessionTimeoutWarningShowAction = Action<typeof SessionTimeoutWarningShowType, boolean>;

    export function setTimeoutMinutes(timeoutMinutes: number): SetTimeoutMinutesAction {
        return createAction(SetTimeoutMinutesType, { timeoutMinutes: timeoutMinutes });
    }

    export function showSessionTimeoutWarning(show: boolean): SessionTimeoutWarningShowAction {
        return createAction(SessionTimeoutWarningShowType, show);
    }

    // Export all of the actions from this file
    export type SessionActions = SetTimeoutMinutesAction | SessionTimeoutWarningShowAction;
}