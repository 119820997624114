import React from 'react';
import { IntlProvider } from 'react-intl';
import * as Containers from './redux-containers';
import { Language } from '../reducers/state';

import { Helmet } from 'react-helmet';
import { CmsText } from '../misc/cms-text';

const rtl: string = 'rtl';
const ltr: string = 'ltr';

/**
 * State props for the Wrapper IntlProvider component
 */
export type IntlProviderWrapperProps = {
    /**
     * The current site language to change the formatting and messages for
     */
    language: Language;

    /**
     * Flag to indicate if language is right to left.
     */
    isRightToLeft: boolean;

    /**
     * Child components to render
     */
    children?: React.ReactNode
};

/**
 * A Wrapper around IntlProvider to bind the language to the Redux state language component,
 * when the site language is changed the provider is updated as well.
 * @param props The language and child components to render
 */
const IntlProviderWrapper = (props: IntlProviderWrapperProps) => {
    const cmsText = new CmsText(props.language.alltext, 'IntlProviderWrapper', 'dashboard');
    return (
        <div className={props.isRightToLeft ? rtl : ''} dir={props.isRightToLeft ? rtl : ltr}>
            <IntlProvider locale={props.language.code} key={props.language.code} defaultLocale="en">
                {props.children}
            </IntlProvider>
            <Helmet>
                <html lang={props.language.code} />
                <title>{cmsText.get('pageTitle.title', 'Korn Ferry Participant Portal – Assessments Dashboard')}</title>
            </Helmet>
        </div>
    );
};

export default Containers.createStateOnly(
    IntlProviderWrapper,
    (state) => ({
        language: state.language,
        isRightToLeft: state.language.isRightToLeft
    })
);
