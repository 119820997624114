import * as State from './state';
import * as Actions from '../actions/header';

/**
 * The default initial state
 */
export const headerInitial: State.Header = {
    showMenu: false
};

/**
 * Reducer for the whole header
 */
export const HeaderReducer =
    (state: State.Header = headerInitial, action: Actions.ToggleMenuActions): State.Header => {
        switch (action.type) {
            case Actions.ToggleMenuType:
                if (state.showMenu) {
                    return {
                        ...state,
                        showMenu: false
                    };
                }
                return {
                    ...state,
                    showMenu: true
                };
            case Actions.HideMenuType:
                // Don't update the state object unless the state has actually changed 
                if (state.showMenu) {
                    return {
                        ...state,
                        showMenu: false
                    };
                } else {
                    return state;
                }
            default:
                return state;
        }
    };