import { CmsText } from "../../misc/cms-text";
import * as Containers from "../redux-containers";

/**
 * State props for the CompletedAssessmentsMessage component
 */
export interface CompletedAssessmentsMessageStateProps {
    /**
     * CMS content
     */
    allText: CmsText
}

/**
 * Renders the All Assessments Completion message on the Dashboard Page if all the Assessments are completed
 * @param props The properties to pass to the component
 */
export const CompletedAssessmentsMessage = (props: CompletedAssessmentsMessageStateProps) => {

    function onCloseWindow() {
        window.close()
    }

    return (
        <div className="assessments-complete-message-banner">
            <div className="assessments-complete-message-banner-background"></div>
            <div className="complete-assessments-message-container">
                <h2>{props.allText.get('title', 'Great job!')}</h2>
                <p> {props.allText.get('description', "You've completed all of your assessments! Your results will be available in Korn Ferry Assess shortly.")}
                </p>
                <div className="close-window-action-container">
                    <button aria-label={props.allText.get('closeWindowButton', "Close Window")} className="close-window-action-button" onClick={onCloseWindow}>
                        {props.allText.get('closeWindowButton', "Close Window")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Containers.createStateOnly(
    CompletedAssessmentsMessage, (state) => ({
        allText: new CmsText(state.language.alltext, 'CompletedAssessmentsMessage', 'dashboard.allAssessmentsCompleted')
    })
)
