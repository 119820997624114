import React from 'react';
import {  NoJsxElement, NullableElement } from '../../misc/common';
import { CmsText } from '../../misc/cms-text';
import * as Containers from '../redux-containers';
import { DispatchProp } from 'react-redux';
import DownloadReport from './downloadReport';
import { ReportModel } from '../../models/report';
import SuccessProfileNotPublishedMessage from './successProfileNotPublishedMessage';

export interface DownloadReportsStateProps {
    /**
     * Component Text
     */
    text: CmsText;

    /**
     * All assessments for candidate
     */
    reports?: ReportModel[];
}

type DownloadReportsAllProps = DownloadReportsStateProps & DispatchProp<any>;

/**
 * Renders a download reports component that downloads the reports from the server.
 * @param props The properties to pass to the component
 */
export class DownloadReports extends React.Component<DownloadReportsAllProps> {
    constructor(props: DownloadReportsAllProps) {
        super(props);
    }

    render(): NullableElement {
        const {reports, text} = this.props;

        if (!reports || reports.length === 0) {
            return NoJsxElement;
        }
       
        return (
            <div>
                <div className="download-reports-container">
                    <h2 className="download-reports-heading">
                        { text.get('reportsAvailable', 'Reports available to download') }
                    </h2>
                    {
                        reports.map((r, index) => {
                            return (<DownloadReport report={r} key={r.projectId + r.reportType + Number(r.date) + r.languageId + r.reportOptions} />);
                        })
                    }
                    <SuccessProfileNotPublishedMessage /> 
                </div>
                <div className="download-reports-divider" />
            </div>
        );
    }
}

export default Containers.createStateOnly<DownloadReportsStateProps>(
    DownloadReports,
    (state) => ({
        text: new CmsText(state.language.alltext, 'DownloadReport', 'dashboard.downloadReport'),
        reports: state.candidate.reports
    })
);