/**
 * Empty object to pass instead of any to make it clearer and to stop tslint complaining.
 */

import { Platform } from "../enums/platform";
import { clearSession, getSessionPlatform } from "./session";

export interface EmptyObject { }

/**
 * Type alias to represent a JSX element which is null and therefore does not need rendering.
 * 
 * This is required in components that return either an element or nothing depending on some kind of condition. 
 * The problem with that though is that the render function of the component will then return the type 
 * JSX.Element | null, and when you try to connect that component to a Redux container component it will fail 
 * type checking because it will state that the element could potentially be null.
 * 
 * To fix this use some TypeScript magic with the post-fix operator ! which says to remove the optional part 
 * of the type to leave what we really want.
 * 
 * @example
 * render() {
 *  if (!condition) {
 *      return NoJsxElement;
 *  }
 *  else {
 *      return <div></div>;
 *  }
 * }
 */
export const NoJsxElement = null!;

/**
 * Redirects the user to the Url, likely to take them outside of the application
 * @param redirectUrl The absolute url to redirect to
 */
export function ExternalRedirect(redirectUrl: string) {
    window.location.href = redirectUrl;
}

/**
 * Redirects the user to the login page, likely to take them outside of the application
 */
export function RedirectToLogin(brandingName?: string, languageId?: number | undefined) {
    let signinUrl: string = process.env.REACT_APP_SIGNIN_URL;
    if (brandingName && brandingName.length > 0) {
        if (!signinUrl.endsWith('/')) {
            signinUrl += '/';
        }
        signinUrl += brandingName;
    }
    if (languageId) {
        signinUrl += '?languageId=' + languageId;
    }
    ExternalRedirect(signinUrl);
}

/**
 * Redirects the user to the session timeout page
 */
export function RedirectToSessionTimeoutPage(): void {
  const pathname: string = "/session-timeout";
  const sessionTimeoutUrl: string = window.location.origin + pathname;
  ExternalRedirect(sessionTimeoutUrl);
}

/**
 * Redirects the user to the session timeout page if user Platform is IC2 else Redirects to Login page
 */
export function RedirectToSource(
  brandingName?: string,
  languageId?: number | undefined
): void {
  /**
   * get Platform from session storage and store it in a variable before clearing out session storage
   */
  const platform = getSessionPlatform();
  /**
   * Check if Platform is IC2 then Redirect to session timeout page else
   * Redirect to Login page
   */
  if (platform == Platform.IC2) {
    RedirectToSessionTimeoutPage();
    return;
  } else {
  /**
   * Clear the JWT from the session storage - it should have already expired so shouldn't
   * need to blacklist it on the server
   */
    clearSession();
    RedirectToLogin(brandingName, languageId);
  }
}

/* * A nullable JSX.Element that can either return a JSX.Element or null. The null return should use NoJsxElement.
 */
export type NullableElement = JSX.Element | null;