import * as State from './state';
import { Actions } from '../actions/language';
import { CmsService } from '../services/cms-service';

/**
 * The default initial state
 */
export const initialLanguage: State.Language = {
    languageId: CmsService.GetSiteLanguage(),
    code: CmsService.GetSiteLanguageCode(),
    alltext: '',
    isLoaded: false,
    fetchingText: false,
    isRightToLeft: false
};

/**
 * Reducer for the whole languages
 */
export const LanguageReducer =
    (state: State.Language = initialLanguage, action: Actions.LanguageActions): State.Language => {
        switch (action.type) {
            case Actions.LanguageChangeSuccessType:
                return {
                    ...state,
                    languageId: action.payload.languageId,
                    code: action.payload.code,
                    alltext: action.payload.alltext,
                    fetchingText: action.payload.fetchingText,
                    isLoaded: action.payload.isLoaded,
                    isRightToLeft: action.payload.isRightToLeft
                };
            case Actions.FetchingSiteTextType:
                return {
                    ...state,
                    fetchingText: action.payload
                };
            case Actions.LanguageChangeErrorType:
                return {
                    ...state,
                    isLoaded: false,
                    lastError: action.payload
                };
            case Actions.SetLanguageIdType:
                return {
                    ...state,
                    languageId: action.payload
                };
            default:
                return state;
        }
    };

