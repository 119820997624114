import * as State from './state';
import * as Actions from '../actions/assessmentReuse';

export const initialState: State.AssessmentReuse = {
    showReuseAssessmentDialog: false,
    assessment: null
};

/**
 * Reducer to manage Assessment Reuse actions
 * @param state The current state of the AssessmentReuse data/models
 * @param action The current action to handle
 * @returns The new state from this action
 */
export const AssessmentReuseReducer = (state: State.AssessmentReuse = initialState, action: Actions.AssessmentReuse): State.AssessmentReuse => {
    switch (action.type) {
        case Actions.ReuseAssessmentShowType:
            return {
                ...state,
                showReuseAssessmentDialog: action.payload.show,
                assessment: action.payload.assessment
            };
        default:
            return state;
    }
};