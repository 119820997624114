import * as State from './state';
import * as Actions from '../actions/branding';

/**
 * The default initial state
 */
export const BrandingInitialState: State.Branding = {
    styles: '',
    loadingBrandingUrl: false,
    logoUrl: '',
    name: undefined,
    hasDashboardManifest: false
};

/**
 * Reducer for Branding
 */
export const BrandingReducer = (state: State.Branding = BrandingInitialState, action: Actions.BrandingActions): State.Branding => {
    switch (action.type) {
        case Actions.GetBrandingFinishType:
            return {
                ...state,
                loadingBrandingUrl: false,
                styles: action.payload.styles,
                logoUrl: action.payload.logoUrl,
                name: action.payload.name,
                hasDashboardManifest: action.payload.hasDashboardManifest
            };
        default:
            return state;
    }
};
