import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import * as  State from '../../reducers/state';
import { EmptyObject, NoJsxElement } from '../../misc/common';
import { GetPreviewCandidate } from '../../actions/candidate';
import withCms from '../higher-order/with-cms';
import DashboardPage from '../../pages/dashboard';
import { Dispatch } from 'redux';

/**
 * Props for the Dashboard preview 
 */
export interface DashboardPreviewDispatchProps {
  setPreviewCandidate: (brandingName?: string) => void;
}

export interface DashboardPreviewRouteProps {
  brandingName?: string;
}

export interface DashboardPreviewStateProps {
  isCandidateLoaded: boolean;
}

const mapDispatchToProps: MapDispatchToProps<DashboardPreviewDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
  setPreviewCandidate: (brandingName?: string) => {
    if (brandingName) {
      dispatch(GetPreviewCandidate(brandingName));
    }
  }
});

const mapStateToProps: MapStateToProps<DashboardPreviewStateProps, EmptyObject> = (state: State.All) => ({
  isCandidateLoaded: state.candidate.isLoaded
});

export type dashboardPreviewProps = DashboardPreviewDispatchProps & DashboardPreviewStateProps & DashboardPreviewRouteProps;

class DashboardPreviewContainer extends React.Component<dashboardPreviewProps, EmptyObject> {
  render() {
    const { setPreviewCandidate, isCandidateLoaded} = this.props;

    setPreviewCandidate(this.props.brandingName);

    if (!isCandidateLoaded) {
      return NoJsxElement;
    }

    return React.createElement(withCms(DashboardPage));
  }
}

export default connect<DashboardPreviewStateProps, DashboardPreviewDispatchProps, DashboardPreviewRouteProps>(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPreviewContainer);