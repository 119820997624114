import SessionTimeoutWarningModal from '../session-timeout-warning/SessionTimeoutWarningModal';
import ReuseDemographicsModal from '../reuse-demographics-dialog/ReuseDemographicsModal';
import ReuseAssessmentModal from '../reuse-assessment-dialog/ReuseAssessmentModal';
import RedirectWarningModal from '../redirect-warning/RedirectWarningModal';
import { EnvironmentConsole } from '../common/environment-console/environment-console';
import HeaderContainer from '../../components/header/header';
import Footer from '../../components/footer/footer';
import IntlProviderContainer from '../../components/intl-provider-container';
import { CmsService } from '../../services/cms-service';
import { Platform } from "../../enums/platform";
import { getSessionPlatform, hasSession } from "../../misc/session";
import * as Containers from "../redux-containers";
import { AssessmentModel } from '../../models/assessment';
import  CompletedAssesementsMessage  from './completedAssessmentsMessage';
import { DashboardPageContents } from './dashboardPageContents';

/**
 * @returns True if this user has completed all the assessments
 */
function allAssessmentsCompleted(assessments: AssessmentModel[]): boolean {
    return assessments.every((assessment) => assessment.status == -1);
}

/**
 * State props for the CompletedAssessmentsMessage component
 */
export interface DashboardPageElementsStateProps {
    /**
     * CMS content
     */
    assessments: AssessmentModel[]
}

/**
 * Method to return elements for dashboard page
 */
export const DashboardPageElements = (props: DashboardPageElementsStateProps): JSX.Element => {

    let isHumburgerMenuIconVisible = true;
    let isLanguageSelectDropdownVisible = true;
    const platform = getSessionPlatform();
    const isPlatformIC2 = (platform == Platform.IC2);
    const isAllAssessmentsCompleted: boolean = allAssessmentsCompleted(props.assessments);
    const isSession: boolean = hasSession();
   
    if (isPlatformIC2) {
        isHumburgerMenuIconVisible = false;
        // hide language select dropdown if all assessments are completed
        isLanguageSelectDropdownVisible = !isAllAssessmentsCompleted;
    }
    
    return (
        <div className={CmsService.GetSiteFontStyle()}>
            <IntlProviderContainer>
            <div className="main-layout">
                <div className="main-layout-header">
                    <EnvironmentConsole />
                    <HeaderContainer showHumburgerMenuIcon={isHumburgerMenuIconVisible} showLanguageSelectDropdown={isLanguageSelectDropdownVisible}/>
                </div>
                <main className="main-layout-content">

                        <div className="assessment-dashboard-container">
                            {isAllAssessmentsCompleted && isPlatformIC2
                                ? <CompletedAssesementsMessage />
                                : <DashboardPageContents />}
                            {/* Display the Session timeout warning modal only if the user is authenticated. No need to display the modal
                             if the page is viewed for branding preview, i.e from route "/Preview/:brandingName", as the user wouldn't be authenticated in that case */}
                            {isSession && <SessionTimeoutWarningModal />}
                            <ReuseDemographicsModal />
                            <RedirectWarningModal />
                            <ReuseAssessmentModal />
                        </div>

                </main>
                <div className="main-layout-footer">
                    <Footer />
                </div>
            </div>
            </IntlProviderContainer>
        </div>
    );
};

export default Containers.createStateOnly(DashboardPageElements, (state) => ({
    assessments: state.candidate.assessments!,
}))

