import * as State from './state';
import * as Actions from '../actions/candidate';

export const initialState: State.Candidate = {
    isLoaded: false,
    isRefreshing: false,
    isPreviewMode: false,
    reportsPreparing: false
};

/**
 * Reducer to manage Candidate actions
 * @param state The current state of the Candidate data/models
 * @param action The current action to handle
 * @returns The new state from this action
 */
export const CandidateReducer = (state: State.Candidate = initialState, action: Actions.Candidate): State.Candidate => {
    switch (action.type) {
        case Actions.GetCandidateDataStartType:
            return {
                ...state,
                isLoaded: false,
                lastError: undefined,
                personal: undefined,
                state: undefined,
                assessments: undefined,
                candidateContactInfo: undefined,
            };
        case Actions.RefreshCandidateDataType:
            return {
                ...state,
                isRefreshing: true,
            };
        case Actions.RefreshCandidateFinishType:
            return {
                ...state,
                isRefreshing: false,
                state: action.payload.state,
                assessments: action.payload.assessments
            };
        case Actions.GetCandidateDataFinishType:
            return {
                ...state,
                isLoaded: true,
                isRefreshing: false,
                lastError: undefined,
                personal: action.payload.personal,
                permissions: action.payload.permissions,
                state: action.payload.state,
                assessments: action.payload.assessments,
                reports: action.payload.reports,
                eLearnings: action.payload.learningContents,
                reportsPreparing: action.payload.reportsPreparing,
                candidateContactInfo: action.payload.candidateContactInfo,
                successProfilePublished: action.payload.successProfilePublished
            };
        case Actions.SetPreviewCandidateType:
            return {
                ...state,
                isLoaded: true,
                isRefreshing: false,
                lastError: undefined,
                isPreviewMode: true,
                personal: action.payload.personal,
                permissions: action.payload.permissions,
                state: action.payload.state,
                assessments: action.payload.assessments,
                candidateContactInfo: action.payload.candidateContactInfo
            };
        case Actions.GetCandidateDataErrorType:
            return {
                ...state,
                isLoaded: false,
                lastError: action.payload
            };
        default:
            return state;
    }
};