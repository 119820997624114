import React from 'react';
import { NoJsxElement, NullableElement } from '../../misc/common';
import { CmsText } from '../../misc/cms-text';
import * as Containers from '../redux-containers';
import { DispatchProp } from 'react-redux';

export interface SuccessProfileWaitingPublicationStateProps {
    /**
     * Component Text
     */
    text: CmsText;

    /**
     * Success profile publication status
     */
    successProfilePublished?: boolean;
}

type SuccessProfileWaitingPublicationAllProps = SuccessProfileWaitingPublicationStateProps & DispatchProp<any>;

/**
 * Renders a component that displays a message that success profile is not yet published
 * @param props The properties to pass to the component
 */
export class SuccessProfileWaitingPublication extends React.Component<SuccessProfileWaitingPublicationAllProps> {
    constructor(props: SuccessProfileWaitingPublicationAllProps) {
        super(props);
    }

    render(): NullableElement {
        const { successProfilePublished, text } = this.props;

        if (successProfilePublished !== undefined && !successProfilePublished) {
            return (
                <div className="sp-waiting-publication-container alert alert-primary d-flex align-items-center">
                    <span className="sp-waiting-publication-icon" />
                    <span className="sp-waiting-publication-message">
                        { text.get('successProfileNotPublished', 'One or more reports are not yet available. You will receive an email notifying you when your feedback is ready.')}
                    </span>
                </div>
            );
        }

        return NoJsxElement;
    }
}

export default Containers.createStateOnly<SuccessProfileWaitingPublicationStateProps>(
    SuccessProfileWaitingPublication,
    (state) => ({
        text: new CmsText(state.language.alltext, 'DownloadReport', 'dashboard.downloadReport'),
        successProfilePublished: state.candidate.successProfilePublished
    })
);