import * as State from './state';
import { Actions } from '../actions/session';

/**
 * The default initial state
 */
export const sessionInitial: State.Session = {
    showTimeoutWarning: false,
    timeoutMinutes: null,
    expiry: null
};

/**
 * Reducer for the whole sesion
 */
export const SessionReducer =
    (state: State.Session = sessionInitial, action: Actions.SessionActions): State.Session => {
        switch (action.type) {
            case Actions.SetTimeoutMinutesType:
                return {
                    ...state,
                    timeoutMinutes: action.payload.timeoutMinutes
                };
            case Actions.SessionTimeoutWarningShowType:
                return {
                    ...state,
                    showTimeoutWarning: action.payload.valueOf()
                };
            default:
                return state;
        }
    };