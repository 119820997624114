import React from 'react';
import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';
import { launchDemographics } from '../../services/demographics-service';
import { logErrorWithAlert } from '../../misc/error';
import { NoJsxElement, EmptyObject } from '../../misc/common';
import { AsyncButton } from '../common/async-button';
import { Actions } from '../../actions/demographics';

/**
 * Props for the demographics
 */
export interface DemographicsStateProps {
    /**
     * Site language
     */
    siteLanguageId: number;

    /**
     * CMS content
     */
    text: CmsText;

    /**
     * Determines if demographics are required, or if they have been switched off for either the project or account.
     */
    showDemographics: boolean;

    /**
     * Determines if the candidate has completed the demographics
     */
    demographicsCompleted: boolean;

    /**
     * Determines if the candidate has previously completed demographics within validity period
     */
    demographicsReusable: boolean;
}

interface DemographicsDispatchProps {
    openReuseDemographics: () => void;
    closeReuseDemographics: () => void;
}

type DemographicsAllProps = DemographicsStateProps & DemographicsDispatchProps;

/**
 * Represents the text header for the assessment details.
 * @param props The properties for this component.
 */
export class Demographics extends React.Component<DemographicsAllProps> {
    constructor(props: DemographicsAllProps) {
        super(props);
        this.begin = this.begin.bind(this);
        this.launch = this.launch.bind(this);
    }

    begin(): Promise<any> {
        if (this.props.demographicsReusable) {
            this.props.openReuseDemographics();
            return Promise.resolve(); // return a dummy promise
        }

        return this.launch();
    }

    launch(): Promise<any> {
        return launchDemographics(this.props.siteLanguageId)
            .then(result => {
                location.href = result;
            })
            .catch(exception => {
                this.setState({ isLoading: false });
                logErrorWithAlert(this.props.text.get('canNotLaunchError', 'Cannot launch assessment'), 'Error launching assessment', exception);
            });
    }

    render() {
        if (!this.props.showDemographics || this.props.demographicsCompleted) {
            return NoJsxElement;
        }

        return (
            <div className="dashboard-about">
                <span className="dashboard-about-item">
                    <span className="dashboard-icon dashboard-icon-demographics" />
                    <div className="dashboard-about-item-text">
                        <h2 className="dashboard-about-title">
                            {this.props.text.get('title', 'Demographics')}
                        </h2>
                        <p className="dashboard-about-description">
                            {this.props.text.get('description', 'Intro')}
                        </p>
                    </div>
                </span>
                <span className="dashboard-about-item dashboard-about-action">
                    <AsyncButton className="dashboard-about-action-button" disabled={false} clickEvent={this.begin}>{this.props.text.get('launch', 'Begin')}</AsyncButton>
                </span>
            </div>
        );
    }
}

export default Containers.createStateAndDispatchWithProps<DemographicsStateProps, DemographicsDispatchProps, EmptyObject>(
    Demographics,
    (state) => ({
        siteLanguageId: state.language.languageId,
        text: new CmsText(state.language.alltext, 'Demographics', 'dashboard.demographics'),
        demographicsCompleted: state.candidate.state!.completedDemographics,
        showDemographics: state.candidate.state!.showDemographics,
        demographicsReusable: state.candidate.state!.demographicsReusable
    }),
    (dispatch) => ({
        openReuseDemographics: () => dispatch<any>(Actions.showReuseDemographics(true)),
        closeReuseDemographics: () => dispatch<any>(Actions.showReuseDemographics(false))
    })
);