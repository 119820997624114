import React from 'react';
import { Navigate } from 'react-router-dom';
import { hasSession, setSessionPlatform } from '../../misc/session';

/**
 * Higher order component to wrap another component around an authentication check. The inner component will only be rendered if there is a local session
 * created, otherwise you will be redirected to the "/authenticate" path.
 * @param Component The actual component to render if authenticated
 * 
 * If not authenticated we send the user back to the "/authenticate" route without a token on purpose because that will handle the lack of token and
 * then force another redirection out of the app back to the KF signin app, which is the intended behaviour if not authenticated.
 */
export default function withAuthentication<Props, State>(Component: React.ComponentClass<Props>): React.ComponentClass<Props> {
    return class extends React.Component<Props, State> {
        render() {
            if (hasSession()) {
                setSessionPlatform()
                return (
                    <Component {...this.props} />
                );
            }  

            return (
                <Navigate to={{ pathname: '/authenticate' }}/>
            );
        }
    };    
}