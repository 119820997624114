import React from 'react';
import ReactModal from 'react-modal';
import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';
import { EmptyObject, NoJsxElement } from '../../misc/common';
import { Actions } from '../../actions/demographics';
import { FormattedDate } from 'react-intl';
import { logErrorWithAlert } from '../../misc/error';
import { launchDemographics } from '../../services/demographics-service';
import { CmsService } from '../../services/cms-service';

interface ReuseDemographicsModalStateProps {
    isOpen: boolean;
    allText: any;
    name: string;
    email: string;
    assessmentDate?: Date;
    lastResponseId?: number;
    siteLanguageId: number;
    modalIsRequired: boolean;
}

interface ReuseDemographicsModalDispatchProps {
    close: () => void;
}

export type ReuseDemographicsModalProps = ReuseDemographicsModalStateProps & ReuseDemographicsModalDispatchProps;

class ReuseDemographicsModal extends React.Component<ReuseDemographicsModalProps, EmptyObject> {
    private okButtonRef: HTMLButtonElement | null;
    private cmsText: CmsText = new CmsText(this.props.allText, 'ReuseDemographicsModal', 'dashboard.demographics');

    constructor(props: ReuseDemographicsModalProps) {
        super(props);
    }

    public componentDidMount() {
        // The app element should be set automatically to 'body' by react-modal but it's giving warnings at runtime saying it's
        // not been set. It's important for accessibiblity with screen-readers so they know what part of the page should be the
        // focus. Seem to be multiple reports online of bugs meaning it's not being set properly. The below will set it to body,
        // which is the default so if the bug is ever fixed, this won't do anything positive or negative
        ReactModal.setAppElement('body');
    }

    public render() {
        if (!this.props.modalIsRequired) {
            return NoJsxElement; 
        }

        const isOpen: boolean = !!this.props.isOpen;

        const title: string = this.cmsText.get('reuseTitle', 'Welcome back');

        const explanation: string = this.cmsText.get('reuseBody', 'It looks like you\'ve recently completed the demographic questionnaire.'
            + ' Consider the context of your previous demographic completion.'
            + ' Please review your previous selections and update any changes such as your education or years of experience.');

        setTimeout(() => this.okButtonRef && this.okButtonRef.focus(), 1);

        return (
            <ReactModal
                isOpen={isOpen}
                className={CmsService.GetSiteFontStyle() + ' reuseDialog-modal'}
                overlayClassName="modal-overlay"
                contentLabel={title + ' ' + explanation}
            >
                <div className="modal-body reuseDialog-body">
                    <h1 className="reuseDialog-title">
                        {title}
                    </h1>
                    <h2 className="reuseDialog-subtitle">
                        {this.cmsText.get('reuseSubtitle', 'You\'ve already completed the demographics')}
                    </h2>
                    <div className="reuseDialog-explanation">
                        {explanation}
                    </div>
                    <table className="reuseDialog-user-details">
                        <thead>
                            <tr>
                                <th>
                                    {this.cmsText.get('name', 'Name')}
                                </th>
                                <th>
                                    {this.cmsText.get('email', 'Email')}
                                </th>
                                <th>
                                    {this.cmsText.get('assessmentDate', 'Assessment date')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {this.props.name}
                                </td>
                                <td>
                                    {this.props.email}
                                </td>
                                <td>
                                    <FormattedDate value={this.props.assessmentDate!} day="numeric" month="short" year="numeric" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="reuseDialog-not-you">
                        <div className="reuseDialog-not-you-img dashboard-icon-info" />
                        <span className="reuseDialog-not-you-text">{this.cmsText.get('notYou', 'If this doesn\'t look like you, please contact your assessment administrator.')}</span>
                    </div>
                </div>
                <div className="reuseDialog-footer">
                    <button onClick={this.handleCancel} className="reuseDialog-button modal-cancel" type="button">
                        {this.cmsText.get('cancelButton', 'Cancel')}
                    </button>
                    <button onClick={this.handleOK} className="reuseDialog-button" type="button" ref={(button) => this.okButtonRef = button}>
                        {this.cmsText.get('reviewAndUpdateButton', 'Review & Update')}
                    </button>
                </div>
            </ReactModal>
        );
    }

    private launch(): Promise<any> {
        return launchDemographics(this.props.siteLanguageId, this.props.lastResponseId)
            .then(result => {
                location.href = result;
            })
            .catch(exception => {
                this.setState({ isLoading: false });
                logErrorWithAlert(this.cmsText.get('canNotLaunchError', 'Cannot launch assessment'), 'Error launching assessment', exception);
            });
    }

    private handleCancel = () => {
        this.props.close();
    }

    private handleOK = () => {
        this.launch();
    }
}

export default Containers.createStateAndDispatchWithProps<ReuseDemographicsModalStateProps, ReuseDemographicsModalDispatchProps, EmptyObject>(
    ReuseDemographicsModal,
    (state) => ({
        isOpen: state.demographics.showReuseDemographicsDialog,
        allText: state.language.alltext,
        name: state.candidate.personal!.displayName,
        email: state.candidate.personal!.email,
        assessmentDate: state.candidate.state!.mostRecentDemographicsDate,
        lastResponseId: state.candidate.state!.mostRecentDemographicsResponseId,
        siteLanguageId: state.language.languageId,
        modalIsRequired: state.candidate.state!.showDemographics && !state.candidate.state!.completedDemographics
    }),
    (dispatch) => ({
        close: () => dispatch<any>(Actions.showReuseDemographics(false))
    })
);
