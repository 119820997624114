import React from 'react';
import { Actions } from '../../actions/language';
import { Actions as SessionActions } from '../../actions/session';
import * as Containers from '../redux-containers';
import { Language } from '../../models/language';
import { CmsText } from '../../misc/cms-text';

interface SiteLanguageOwnProps {
    /**
     * A list of languages that should be displayed, if null or empty then display all languages.
     */
    supportedLanguageIds?: number[];

    /**
     * Text to display as the please select a value, not included if not defined.
     */
    pleaseSelectText?: string;

    /**
     * Determines if the language select is disabled.
     */
    disabled?: boolean;
}

interface SiteLanguageStateProps {
    /**
     * The language of the site
     */
    languageId: number;

    /**
     * All available languages in the system.
     */
    languages: Language[];

    allText: any;
}

interface SiteLanguageDispatchProps {
    /**
     * Change the site language
     */
    changeLanguage: (languageId: number) => void;
    showModal: () => void;
}

/**
 * Combined typed props for site language component
 */
export type SiteLanguageProps = SiteLanguageOwnProps & SiteLanguageStateProps & SiteLanguageDispatchProps;

type SiteLanguageState = {
    languageIdSelected: number
};

/**
 * Renders a drop down for the site language, with the option of filtering the list for the languages available for an assessment
 * and adding an optional please select option to force the language to be changed.
 */
class SiteLanguage extends React.Component<SiteLanguageProps, SiteLanguageState> {
    constructor(props: SiteLanguageProps) {
        super(props);
        this.state = { 
            languageIdSelected: this.props.languageId 
        };
    }

    onLanguageChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const languageIdSelected = parseInt(event.currentTarget.value, 10);
        this.setState({ languageIdSelected });
        // Announce the language change
        const selectedLanguage = this.props.languages.find(language => language.id === languageIdSelected);
        const liveRegion = document.getElementById('liveRegion');
        if (liveRegion) {
            liveRegion.innerText = `${selectedLanguage ? selectedLanguage.nativeName : ''} Selected`;
        }
        setTimeout(()=>{
            this.props.changeLanguage(languageIdSelected);
        }, 5000);
    }

    public render() {
        let displayLanguages = (this.props.supportedLanguageIds !== undefined && this.props.supportedLanguageIds.length >= 1)
            ? this.props.languages.filter(l => this.props.supportedLanguageIds!.some(s => s === l.id))
            : this.props.languages;
        let cmsText: CmsText = new CmsText(this.props.allText, 'SiteLanguage', 'navBarMenu');

        return (
            <div className="language-dropdown">
                <select
                    className="form-control form-select form-select-sm"
                    onChange={event => this.onLanguageChange(event)}
                    value={this.state.languageIdSelected}
                    aria-label={cmsText.get('language', 'Language selection')}
                    aria-expanded={false}
                    aria-description={cmsText.get('navigateInstruction', 'Press the enter key to expand and use the arrow keys to navigate.')}
                >
                    {this.props.pleaseSelectText !== undefined &&
                        <option disabled={true} value={this.props.languageId}>{this.props.pleaseSelectText}</option>
                    }
                    {displayLanguages.map(
                        (language) => <option key={language.id} value={language.id}> {language.nativeName} </option>

                    )}
                </select>
                <div
                    id="liveRegion"
                    aria-live="assertive"
                    aria-atomic="true"
                >   
                </div>
            </div>
        );
    }
}

export default Containers.createStateAndDispatchWithProps<SiteLanguageStateProps, SiteLanguageDispatchProps, SiteLanguageOwnProps>(
    SiteLanguage,
    (state) => ({
        languageId: state.language.languageId,
        languages: state.language.alltext.allLanguages,
        allText: state.language.alltext
    }),
    (dispatch) => ({
        changeLanguage: (languageId: number) => dispatch<any>(Actions.changeLanguage(languageId, true)),
        showModal: () => dispatch<any>(SessionActions.showSessionTimeoutWarning(true))
    })
);