import * as State from './state';
import * as Actions from '../actions/reset-candidate';

export const initialResetCandidate: State.ResetCandidate = {
    resettingCandidate: false
};

/**
 * Reducer to manage reset candidate actions
 * @param state The current state of the reset Candidate data/models
 * @param action The current action to handle
 * @returns The new state from this action
 */
export const ResetCandidateReducer = (state: State.ResetCandidate = initialResetCandidate, action: Actions.ResetCandidateActions): State.ResetCandidate => {
    switch (action.type) {
        case Actions.ResetCandidateStartType:
            return {
                ...state,
                lastError: undefined,
                resettingCandidate: action.payload
            };
        case Actions.ResetCandidateSuccessType:
            return {
                ...state,
                lastError: undefined,
                resettingCandidate: action.payload
            };
        case Actions.ResetCandidateFailedType:
            return {
                ...state,
                lastError: action.payload,
                resettingCandidate: false
            };
        default:
            return state;
    }
};