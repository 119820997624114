import React from 'react';
import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';
import { DispatchProp } from 'react-redux';
import { AsyncButton } from '../common/async-button';
import { getReport } from '../../services/report-service';
import { logErrorWithAlert } from '../../misc/error';
import { ReportModel } from '../../models/report';
import { FormattedDate } from 'react-intl';

interface DownloadReportOwnProps {
    /**
     * The report to launch.
     */
    report: ReportModel;
}

interface DownloadReportStateProps {
    /**
     * Component Text
     */
    text: CmsText;
}

type DownloadReportAllProps = DownloadReportOwnProps & DownloadReportStateProps & DispatchProp<any>;

/**
 * Renders a launch assessment component that starts the assessment in the Reflex system.
 * @param props The properties to pass to the component
 */
export class DownloadReport extends React.Component<DownloadReportAllProps> {
    constructor(props: DownloadReportAllProps) {
        super(props);
        this.download = this.download.bind(this);
    }

    download(reportType: string, projectId: number, reportLanguage: number, reportOptions?: string): Promise<any> {
        return getReport(projectId, reportType, reportLanguage, reportOptions)
            .catch(exception => {
                logErrorWithAlert(this.props.text.get('canNotDownloadReport', 'Cannot download report'), 'Error downloading report', exception);
            });
    }

    render() {
        let projectId = this.props.report.projectId;
        let reportType = this.props.report.reportType;
        let languageId = this.props.report.languageId;
        let reportOptions = this.props.report.reportOptions;
        let reportName = this.props.report.name;
        let reportDate = this.props.report.date;

        return (
            <div className="download-reports-report" key={reportType + projectId}>
                <AsyncButton className="download-reports-button" clickEvent={() => this.download(reportType, projectId, languageId, reportOptions)} >
                    <span className="download-reports-icon" />
                    <div className="download-reports-text-container">
                        <span className="download-reports-name">{reportName}</span>
                        <div className="download-reports-date"><FormattedDate value={reportDate!} day="numeric" month="long" year="numeric" /></div>
                    </div>
                </AsyncButton>
            </div>
        );
    }
}

export default Containers.createStateWithProps<DownloadReportStateProps, DownloadReportOwnProps>(
    DownloadReport,
    (state) => ({
        text: new CmsText(state.language.alltext, 'DownloadReport', 'dashboard.downloadReport')
    })
);