import React from 'react';

/**
 * Props for controlling the display of the loading component
 */
export interface LoadingProps {
    /**
     * Determine if the loader loads in full screen mode.
     */
    fullScreen?: boolean;
}

/**
 * the defaults props to use, to make the values optional.
 */
const defaults = {
    fullScreen: true
};

/**
 * Renders a simple loading screen
 */
export const Loading: React.FunctionComponent<LoadingProps> = (props: LoadingProps) => {

    if (props.fullScreen) {
        return (
            <div className="spinner-fullscreen-container">
                <img className="spinner spinner-fullscreen" src="/images/spinner.svg" />
            </div>);
    }

    return <img className="spinner spinner-inline" src="/images/spinner.svg" />;
};

Loading.defaultProps = defaults;