import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';

/**
 * Props for the assessment header title
 */
export interface AssessmentHeaderProps {
    /**
     * CMS content
     */
    text: CmsText;
}

/**
 * Represents the text header for the assessment details.
 * @param props The properties for this component.
 */
const AssessmentHeader = (props: AssessmentHeaderProps) => (
    <tr className="incomplete-assessments-grid-title">
        <th className="assessment-container-item" id="incomplete-assessments-grid-title-column-assessment" scope="col">{props.text.get('assessment', 'Assessment')}</th>
        <th className="assessment-container-item" id="incomplete-assessments-grid-title-column-time" scope="col">{props.text.get('time', 'Time')}</th>
        <th className="assessment-container-item" id="incomplete-assessments-grid-title-column-status" scope="col">{props.text.get('status', 'Status')}</th>
        <th className="assessment-container-column" scope="col">{props.text.get('action', 'Actions')}</th>
    </tr>
);

export default Containers.createStateOnly(
    AssessmentHeader,
    (state) => ({
        text: new CmsText(state.language.alltext, 'AssessmentHeader', 'dashboard.headings')
    })
);
