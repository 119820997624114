import { postText, deleteObject } from './api-service';

/**
 * Authorize all future API calls using the provided login token. This *must* be the first API call used or any future API calls will not work.
 * @param token The login token that was provided via the KF Signin app.
 * @return A new authentication token to be used just for this app and the duration of this user's session.
 */
export const authorize = (token: string): Promise<string> => postText('authorize', token, { authorize: false });

/**
 * Request a new JWT for use with future requests.
 */
export const extendSession = (): Promise<string> => postText('extendSession', '');

/**
 * Logout the user from their session, this *must* be called before clearSession as the API token is required for this call.
 */
export const logout = (): Promise<string> => deleteObject('session');