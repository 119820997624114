import { NoJsxElement } from '../../../misc/common';
import classNames from 'classnames';
 
/**
 * Development environment console, displays the current environment and version in the top left hand corner of the page, 
 * to make it obvious which environment is being used, it also helps with screen shots.
 */
export const EnvironmentConsole = () => {
    let environment = process.env.REACT_APP_ENV;
    let showConsole = (environment === 'local' || environment === 'KFAS-Dashboard-Dev' || environment === 'KFAS-Dashboard-Qa');

    if (!showConsole) {
        return NoJsxElement;
    }
    
    let classes = classNames({
        'console': true,
        ['console-' + environment]: true
    });

    return (
    <div className={classes} >
        {environment} - {process.env.REACT_APP_VERSION}
    </div>);
};