import React from 'react';
import { NoJsxElement } from '../../misc/common';
import { logErrorWithAlert } from '../../misc/error';
import { CmsText } from '../../misc/cms-text';
import { AsyncButton } from '../common/async-button';
import { getReport } from '../../services/report-service';
import * as Containers from '../redux-containers';
import { AssessmentModel } from '../../models/assessment';
import { DispatchProp } from 'react-redux';

/**
 * Own props for the download report component
 */
export interface LegacyDownloadReportOwnProps {
    /**
     * The assessment to download reports for.
     */
    assessment: AssessmentModel;
}

export interface LegacyDownloadReportStateProps {
    /**
     * Component Text
     */
    text: CmsText;

    /**
     * Checks if reports are accessible.
     */
    canAccessReports: boolean;

    /**
     * Indicates if the project is a blended (hub) project
     */
    isBlendedProject: boolean;
}

type LegacyDownloadReportAllProps = LegacyDownloadReportOwnProps & LegacyDownloadReportStateProps & DispatchProp<any>;

/**
 * Renders a download report component that downloads the reports from the server.
 * @param props The properties to pass to the component
 */
export class LegacyDownloadReport extends React.Component<LegacyDownloadReportAllProps> {
    constructor(props: LegacyDownloadReportAllProps) {
        super(props);
        this.download = this.download.bind(this);
    }

    download(): Promise<any> {
        return getReport(this.props.assessment.assessmentId)
            .catch(exception => {
                logErrorWithAlert(this.props.text.get('canNotDownloadReport', 'Cannot download report'), 'Error downloading report', exception);
            });
    }

    render() {
        // Blended (hub) projects list reports at the top of the page instead of with assessments
        if (this.props.isBlendedProject || !this.props.canAccessReports) {
            return NoJsxElement;
        }

        const text = this.props.text.get('getReport', 'Report');
        return (
            <div className="assessment-container-action__panel">
                <span className="assessment-container-action-start">
                    <AsyncButton className="assessment-container-action-start-button" disabled={false} clickEvent={this.download}>{text}</AsyncButton>
                </span>
            </div>
        );
    }
}

export default Containers.createStateWithProps<LegacyDownloadReportStateProps, LegacyDownloadReportOwnProps>(
    LegacyDownloadReport,
    (state) => ({
        text: new CmsText(state.language.alltext, 'DownloadReport', 'dashboard.downloadReport'),
        canAccessReports: state.candidate.permissions!.viewCandidateReport,
        isBlendedProject: state.candidate.state!.isInBlendedProject
    })
);
