import { CmsText } from "../../misc/cms-text";
import Footer from "../footer/footer";
import * as Containers from "../redux-containers";
import HeaderContainer from "../header/header";
import { useEffect } from "react";
import { clearCandidateDataFromSessionStorage } from "../../misc/session";
import IntlProviderContainer from "../intl-provider-container";
import { CmsService } from "../../services/cms-service";

/**
 * State props for the Sanctioned component.
 */
export interface SessionTimeoutStateProps {
  text: CmsText;
}

export function SessionTimeOut(props: SessionTimeoutStateProps) {

  useEffect(() => {
    // Clear Candidate data from the session storage 
    clearCandidateDataFromSessionStorage();
  }, []);

  return (
    <div className={CmsService.GetSiteFontStyle()}>
    <IntlProviderContainer>
      <div className="main-layout">
        <div className="main-layout-header">
          <HeaderContainer showHumburgerMenuIcon={false} showLanguageSelectDropdown={false} />
        </div>
        <main className="main-layout-content">
          <div className="centered-layout">
            <p className="session-timeout-statement">
              {props.text.get(
                "message",
                "Your session has timed out due to inactivity. Please close this window and use your assessment link to return."
              )}
            </p>
          </div>
        </main>
        <div className="main-layout-footer">
          <Footer />
        </div>
      </div>
    </IntlProviderContainer>
    </div>
  );
}

export default Containers.createStateOnly(SessionTimeOut, (state) => ({
  text: new CmsText(state.language.alltext, "SessionTimeOut", "loggedOut"),
}));