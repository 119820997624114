import { Action, createAction } from './actions';
import { EmptyObject } from '../misc/common';

/**
 * Allow a candidate to reset all test
 */
export const ToggleMenuType = 'MENU-TOGGLED';
export const HideMenuType = 'MENU-HIDE';

export type ToggleMenuAction = Action<typeof ToggleMenuType, EmptyObject>;
export type HideMenuAction = Action<typeof HideMenuType, EmptyObject>;

/**
 * Toggle menu based on current menu state 
 */
export function toggleMenu(): ToggleMenuAction {
    return createAction(ToggleMenuType, {});
}

/**
 * Hide menu  
 */
export function hideMenu(): HideMenuAction {
    return createAction(HideMenuType, {});
}

// Export all of the actions from this file.
export type ToggleMenuActions = ToggleMenuAction | HideMenuAction;