// @ts-nocheck

import React from 'react';
import * as State from '../../reducers/state';
import { connect } from 'react-redux';
import { Loading } from '../common/loading/loading';
import { getCandidateData } from '../../actions/candidate';
import { Dispatch } from 'redux';
import { ResponseError } from '../../services/api-service';
import { DevErrorView } from '../common/dev-error-view';
import { RedirectToSource } from '../../misc/common';

/** State props for the WithCandidateData HOC */
export interface WithCandidateDataStateProps {
    isLoaded: boolean;
    lastError?: ResponseError;
    isRefreshing: boolean;
}

/** Dispatch props for the WithCandidateData HOC */
export interface WithCandidateDataDispatchProps {
    onRequestData: () => void;
}

/**
 * Higher order component to ensure that candidate data has been fetched from the server and is present in the Redux store before
 * rending the inner component provided.
 * @param Component The actual component to render once data is present
 * 
 * This HOC is required in the case of the page being refreshed in the browser, the session will exist but the Redux store would be empty.
 * To get around this render a loading spinner whilst doing the initial data fetch, then once the store has been updated render the real
 * page.
 */
export default function withCandidateData<P, S>(Component: React.ComponentClass<P>): React.ComponentClass<P> {

    const mapStateToProps = (state: State.All, ownProps: P) => ({
        isLoaded: state.candidate.isLoaded,
        lastError: state.candidate.lastError,
        isRefreshing: state.candidate.isRefreshing
    });

    const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
        onRequestData: () => {
            dispatch(getCandidateData());
        }
    });

    type WithCandidateDataProps = P & WithCandidateDataStateProps & WithCandidateDataDispatchProps;

    class C extends React.Component<WithCandidateDataProps, S> {
        inDevMode = process.env.NODE_ENV === `development`;

        render() {
            // If the candidate has been loaded and partial data for candidate is not refreshing                     
            if (this.props.isLoaded && !this.props.isRefreshing) {
                return <Component {...this.props} />;
            } else if (this.props.isRefreshing) {
                return <Loading />;
            }

            if (this.props.lastError !== undefined) {
                if (this.inDevMode) {
                    return (
                        <DevErrorView
                            message="An error occurred during candidate data fetch"
                            errorResponse={this.props.lastError}
                        />
                    );
                }

                // Kick back out to the KF signin app or session timeout page if user platform is IC2
                RedirectToSource();
            }

            this.props.onRequestData();
            return <Loading />;
        }
    }

    return connect(
        mapStateToProps, 
        mapDispatchToProps)(C);
}