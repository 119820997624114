import React from 'react';
import ReactModal from 'react-modal';
import * as Containers from '../redux-containers';
import { CmsText } from '../../misc/cms-text';
import { EmptyObject, RedirectToSource } from '../../misc/common';
import { Actions } from '../../actions/session';
import { extendSession } from '../../services/authorize-service';
import { createSession, getSessionDetails, getClockOffset} from '../../misc/session';
import { hasBranding, getBranding } from '../../misc/brandingSession';
import { CmsService } from '../../services/cms-service';

interface SessionTimeoutWarningModalStateProps {
    isOpen: boolean;
    allText: any;
    timeoutMinutes: number | null;
    languageId: number;
}

interface SessionTimeoutWarningModalDispatchProps {
    setTimeoutMinutes: (timeoutMinutes: number) => void;
    open: () => void;
    close: () => void;
}


export type SessionTimeoutWarningModalProps = SessionTimeoutWarningModalStateProps & SessionTimeoutWarningModalDispatchProps ;

class SessionTimeoutWarningModal extends React.Component<SessionTimeoutWarningModalProps, EmptyObject> {
    private buttonRef: HTMLButtonElement | null;
    private sessionEndBrowserTimeout: number | null;

    constructor(props: SessionTimeoutWarningModalProps) {
        super(props);
    }

    public componentDidMount() {
        // The app element should be set automatically to 'body' by react-modal but it's giving warnings at runtime saying it's
        // not been set. It's important for accessibiblity with screen-readers so they know what part of the page should be the
        // focus. Seem to be multiple reports online of bugs meaning it's not being set properly. The below will set it to body,
        // which is the default so if the bug is ever fixed, this won't do anything positive or negative
        ReactModal.setAppElement('body');
        
        this.checkSessionTimeout();
    }

    public render() {
        const cmsText: CmsText = new CmsText(this.props.allText, 'Session Timeout Warning', 'modals.sessionTimeoutWarning');

        const isOpen: boolean = !!this.props.isOpen;

        let title: string = cmsText.get('title', 'Are you still here?');

        let body: string = cmsText.get('body', 'Your current session is about to expire. '
            + 'For your security, the session automatically ends after ${minutes} of inactivity.'
            + 'This greatly reduces the chances that someone else will access your information if you forgot to log out.');
        if (body && this.props.timeoutMinutes) {
            body = body.split('${minutes}').join(this.props.timeoutMinutes.toString());
        }

        const okButton: string = cmsText.get('okButton', 'I\'m Here');

        return (
            <ReactModal
                isOpen={isOpen}
                className={CmsService.GetSiteFontStyle() + ' timeout-modal'}
                overlayClassName="modal-overlay"
                contentLabel={title + ' ' + body}
                ariaHideApp={false}
                role="alert"
            >
                <div className="modal-header" aria-label={title}>
                    {title}
                </div>
                <div className="modal-body" aria-label={body}>
                    {body}
                </div>
                <div className="modal-footer">
                    <button onClick={this.handleClose} className="modal-button" ref={(button) => this.buttonRef = button} aria-label={okButton}>{okButton}</button>
                </div>
            </ReactModal>
        );
    }

    private checkSessionTimeout = () => {
        const warningTime: number = 3 * 60000; // time before expiry to display warning (in ms)
        const timeRemaining: number = this.getTimeRemaining();

        if (timeRemaining <= warningTime) {
            this.open();
        } else {
            setTimeout(this.checkSessionTimeout, timeRemaining - warningTime);
        }
    }

    private getTimeRemaining = (): number => {
        const details = getSessionDetails();

        // if the timeoutMinutes calculated from the JWT is different to the value
        // we have in the redux state, then we should update the redux - this normally
        // won't change, it will primarily be used to initialise the value
        if (details.timeoutMinutes !== this.props.timeoutMinutes) {
            this.props.setTimeoutMinutes(details.timeoutMinutes);
        }

        const nowWithoutOffset = Date.now() - getClockOffset();

        return details.expiry.getTime() - nowWithoutOffset;
    }

    private open = () => {
        this.props.open();

        // Set the focus to the button when the dialog opens
        setTimeout(() => this.buttonRef && this.buttonRef.focus(), 1);

        // Return to login page when session has timed out
        const timeRemaining: number = Math.max(this.getTimeRemaining(), 0);
        this.sessionEndBrowserTimeout = window.setTimeout(this.handleSessionTimedOut, timeRemaining);
    }

    private handleClose = () => {
        // Extend the JWT token before closing the dialog
        extendSession()
            .then(newJWT => {
                createSession(newJWT);

                // Clear the timeout for redirect to login
                if (this.sessionEndBrowserTimeout) {
                    window.clearTimeout(this.sessionEndBrowserTimeout);
                }

                this.props.close();
                this.checkSessionTimeout();
            });
    }

    private handleSessionTimedOut = () => {
        let brandingName = '';
        if (hasBranding()) {
            brandingName = getBranding().name;
        }
        RedirectToSource(brandingName, this.props.languageId) 
    }
}

export default Containers.createStateAndDispatchWithProps<SessionTimeoutWarningModalStateProps, SessionTimeoutWarningModalDispatchProps, EmptyObject>(
    SessionTimeoutWarningModal,
    (state) => ({
        isOpen: state.session.showTimeoutWarning,
        allText: state.language.alltext,
        expiry: state.session.expiry,
        timeoutMinutes: state.session.timeoutMinutes,
        languageId: state.language.languageId
    }),
    (dispatch) => ({
        setTimeoutMinutes: (timeoutMinutes: number) => dispatch<any>(Actions.setTimeoutMinutes(timeoutMinutes)),
        open: () => dispatch<any>(Actions.showSessionTimeoutWarning(true)),
        close: () => dispatch<any>(Actions.showSessionTimeoutWarning(false))
    })
);
