import * as State from './state';
import { Actions } from '../actions/redirect-warning';

/**
 * The default initial state
 */
export const redirectWarningInitial: State.RedirectWarning = {
    showRedirectWarningModal: false,
    onRedirectConfirmButtonClick: undefined
};

/**
 * Reducer for the RedirectWarning
 */
export const RedirectWarningReducer =
    (state: State.RedirectWarning = redirectWarningInitial, action: Actions.RedirectWarningActions): State.RedirectWarning => {
        switch (action.type) {
            case Actions.RedirectWarningShowType:
                return {
                    ...state,
                    showRedirectWarningModal: action.payload.show.valueOf(),
                    onRedirectConfirmButtonClick: action.payload.onRedirectConfirmButtonClick
                };
            default:
                return state;
        }
    };