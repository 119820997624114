import * as Containers from '../redux-containers';
import format from 'string-template';
import StepProgressBar from '../common/step-progress-bar/step-progress-bar';
import { AssessmentModel, AssessmentStatus } from '../../models/assessment';
import { CmsText } from '../../misc/cms-text';

/**
 * State props for the Overall progress component
 */
export interface OverallProgressStateProps {
    /**
     * The total number of assessments the candidate has to complete
     */
    totalAssessments: number;

    /**
     * The number of assessments the candidate has completed.
     */
    completedAssessments: number;

    /**
     * The number of assessments, which are locked.
     */
    lockedAssessments: number;

    /**
     * CMS content
     */
    componentText: CmsText;
}

/**
 * Renders the overall progress of the dashboard, the progress bar and summary text for the total number of assessments completed.
 * @param props The properties to pass to the component
 */
export const OverallProgress = (props: OverallProgressStateProps) => {
    let defaultNoCompletedText = 'You have not completed any assessments yet';
    let defaultSomeCompletedPart1 = 'Your Progress: ';
    let defaultSomeCompletedPart2 = ' {completed} of {total} Assessments Complete';
    let defaultSomeCompletedPart3 = ' {completed} of {total} Assessments Complete ({locked} assessments have expired)';

    let noCompletedText = props.componentText.get('noCompletedAssessments', defaultNoCompletedText);
    let someCompletedText1 = props.componentText.get('someCompletedAssessmentsPart1', defaultSomeCompletedPart1);
    let someCompletedText2 = props.componentText.get('someCompletedAssessmentsPart2', defaultSomeCompletedPart2);
    let someCompletedText3 = props.componentText.get('someCompletedAssessmentsPart3', defaultSomeCompletedPart3);
    let someCompletedText = props.lockedAssessments > 0 ? someCompletedText3 : someCompletedText2;

    let statusText = (props.completedAssessments === 0) 
        ?
            (
                <div 
                    id="main-content" className="dashboard-progress-title dashboard-progress-title-no-completed"
                    data-testid="dashboard-progress-title">
                    {noCompletedText}
                </div>
            ) 
        :
            (
                <div  id="main-content" className="dashboard-progress-title" data-testid="dashboard-progress-title">
                    <span className="dashboard-progress-title-appeal">
                        {someCompletedText1}
                    </span>
                    {
                        format(someCompletedText, {
                            'completed': props.completedAssessments,
                            'total': props.totalAssessments,
                            'locked': props.lockedAssessments
                        })
                    }
                </div>
            );

    return (
        <div className="dashboard-progress">
            <StepProgressBar maxSteps={props.totalAssessments} currentStep={props.completedAssessments} />
            {statusText}
        </div>);
};

export default Containers.createStateOnly(
    OverallProgress,
    (state) => ({
        totalAssessments: state.candidate.assessments!.filter(function (a: AssessmentModel) { return (!(a.status !== AssessmentStatus.Completed && a.isLocked)); }).length,
        completedAssessments: state.candidate.assessments!.filter(function (a: AssessmentModel) { return (a.status === AssessmentStatus.Completed); }).length,
        lockedAssessments: state.candidate.assessments!.filter(function (a: AssessmentModel) { return ((a.status !== AssessmentStatus.Completed && a.isLocked)); }).length,
        componentText: new CmsText(state.language.alltext, 'OverallProgress', 'dashboard.overallProgress')
    })
);