import { getObject } from './api-service';

/**
 * Gets the launch url to start a KF Learn session by redirecting the browser to the provided url.
 * @param learningContentId The learning content to launch for the candidate.
 */
export const launchKFLearningSite = (learningContentId: number) =>
    getObject(
        'learning',
        (json) => <string> json,
        {
            id: learningContentId
        });