import { Action, createAction } from './actions';
import { ThunkAction } from 'redux-thunk';
import { ResponseError } from '../services/api-service';
import { resetCandidate } from '../services/reset-candidate-service';
import { refreshCandidateData } from './candidate';
import * as State from '../reducers/state';
import { AnyAction } from 'redux';

/**
 * Allow a candidate to reset all test
 */
export const ResetCandidateSuccessType = 'RESET-CANDIDATE-SUCCESS';
export const ResetCandidateStartType = 'RESET-CANDIDATE-START';
export const ResetCandidateFailedType = 'RESET-CANDIDATE-FAILED';

export type ResetCandidateSuccessAction = Action<typeof ResetCandidateSuccessType, boolean>;
export type ResetCandidateStartAction = Action<typeof ResetCandidateStartType, boolean>;
export type ResetCandidateFailedAction = Action<typeof ResetCandidateFailedType, ResponseError>;

/**
 * Action: Reset  Candidate (Finish async)
 * Async action resulting from a fetch request to reset candidate. 
 * @param {boolean} resettingCandidate 
 * @returns {ResetCandidateSuccessAction} 
 */
export function resetCandidateSuccess(): ResetCandidateSuccessAction {
    return createAction(ResetCandidateSuccessType, false);
}

/**
 * Action: Reset Candidate ( Start async)
 * Async action to start resetting candidate data
 * @param {boolean} resettingCandidate 
 * @returns {ResetCandidateStartAction} 
 */
export function resetCandidateStart(): ResetCandidateStartAction {  
    return createAction(ResetCandidateStartType, true);
}

/**
 * Action: Reset candidate ( failed async)
 * Async action resulting from failed reset candidate request. 
 * @param {ResponseError} error 
 * @returns {ResetCandidateFailedAction} 
 */
export function resetCandidateFailed(error: ResponseError): ResetCandidateFailedAction {   
    return createAction(ResetCandidateFailedType, error);
}

export function ResetCandidate(): ThunkAction<void, State.All, unknown, AnyAction> {   
    return (dispatch) => {       
        dispatch(resetCandidateStart());
        return resetCandidate()
            .then(x => {
                dispatch<any>(refreshCandidateData());
            })
            .then(r => {
                dispatch(resetCandidateSuccess());
            })
            .catch((error: ResponseError) => dispatch(resetCandidateFailed(error)));
    };
}

// Export all of the actions from this file.
export type ResetCandidateActions = ResetCandidateSuccessAction | ResetCandidateStartAction | ResetCandidateFailedAction;