import { getObject } from './api-service';

/**
 * Gets the launch url to start a Reflex assessment demographics by redirecting the browser to the provided url.
 * @param language The language of the assessment.
 */
export const launchDemographics = (languageId: number, lastResponseId: number | undefined = undefined) =>
    getObject(
        'demographics/',
        (json) => <string> json,
        {
            language: languageId,
            responseId: lastResponseId
        });