import React from 'react';
import { EmptyObject } from '../misc/common';
import  DashboardPageElements  from '../components/dashboard/dashboardPage';
import { RouterMetadata } from '../components/higher-order/with-router';

/**
 * Renders the entire Dashboard page.
 */

/**
 * Props for the DashboardPage, values passed in via React Router
 */
type DashboardPageProps = {
  /**
   * React Router meta data such as location, params, navigation
   */
  router: RouterMetadata
};

export default class DashboardPage extends React.Component<DashboardPageProps, EmptyObject> {
  render() {
    return  <DashboardPageElements/>;
  }
}

