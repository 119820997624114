enum classNameType {
    LARGE = 'container__large',
    DEFAULT = 'container__default'
}

/**
 * FontResize
 * service to subscribe font size changes
 */
export class FontResize {
    private newSize: string;
    private oldTextSize: string;
    private intervalId: number;
    private html: HTMLHtmlElement | null = document.querySelector('html');

    public startDetect(): void {
        this.intervalId = window.setInterval(this.detectTextSize, 700);
    }

    public stopDetect(): void {
        window.clearInterval(this.intervalId);
    }

    private detectTextSize = () => {
        this.newSize = this.getTextSizeFromElement();

        if (this.newSize !== this.oldTextSize) {
            this.updateRootClassName();
            this.oldTextSize = this.newSize;
        }
    }

    /**
     * Method to detect current font-size
     */
    private getTextSizeFromElement = () => {
        let initialFontSize = '';

        if (this.html) {
            initialFontSize = window
                .getComputedStyle(this.html)
                .getPropertyValue('font-size');
        }

        return initialFontSize;
    }

    /**
     * Method to update className for root html element
     */
    private updateRootClassName(): void {
        if (this.html) {
            if (parseInt(this.newSize, 10) >= 34) {
                this.html.className = classNameType.LARGE;
            } else {
                this.html.className = classNameType.DEFAULT;
            }
        }
    }
}

export default FontResize;