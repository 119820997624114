import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { EmptyObject } from '../../misc/common';
import * as  State from '../../reducers/state';
import { hasBranding, getBranding } from '../../misc/brandingSession';
import { Helmet } from 'react-helmet';

/**
 * State props for the branding component
 */

export type BrandingWrapperProps = {
    styles: string;
    children?: React.ReactNode
};
const mapStateToProps: MapStateToProps<BrandingWrapperProps, EmptyObject> = (state: State.All) => ({
    styles: state.branding.styles
});

const BrandingWrapper = (props: BrandingWrapperProps) => (
    <div>
        <Helmet>
            <style>{hasBranding() && getBranding().styles || props.styles}</style>
        </Helmet>
    </div>
);

export default connect(
    mapStateToProps
)(BrandingWrapper);