import { ThunkAction } from 'redux-thunk';
import { EmptyObject } from '../misc/common';
import { CandidateModel } from '../models/candidate';
import * as State from '../reducers/state';
import { ResponseError } from '../services/api-service';
import { getData, GetDataPreviewCandidate } from '../services/candidate-service';
import { Action, createAction } from './actions';
import { finishGetBranding } from '../actions/branding';
import { storeCandidateId } from '../misc/session';
import { BrandingService } from '../services/branding-service';
import { logError } from '../misc/error';
import { CmsService } from '../services/cms-service';
import { Actions } from './language';
import { AnyAction } from 'redux';

export const GetCandidateDataStartType = 'GET-CANDIDATE-DATA-START';
export type GetCandidateDataStartAction = Action<typeof GetCandidateDataStartType, EmptyObject>;

export const RefreshCandidateDataType = 'REFRESH-CANDIDATE-DATA-START';
export type RefreshCandidateDataStartAction = Action<typeof RefreshCandidateDataType, EmptyObject>;

export const RefreshCandidateFinishType = 'REFRESH-CANDIDATE-DATA-FINISH';
export type RefreshCandidateDataFinishAction = Action<typeof RefreshCandidateFinishType, CandidateModel>;

export const GetCandidateDataFinishType = 'GET-CANDIDATE-DATA-FINISH';
export type GetCandidateDataFinishAction = Action<typeof GetCandidateDataFinishType, CandidateModel>;

export const GetCandidateDataErrorType = 'GET-CANDIDATE-DATA-ERROR';
export type GetCandidateDataErrorAction = Action<typeof GetCandidateDataErrorType, ResponseError>;

export const SetPreviewCandidateType = 'SET-PREVIEW-CANDIDATE';
export type SetPreviewCandidateAction = Action<typeof SetPreviewCandidateType, CandidateModel>;

/** 
 * Action: Get Candidate Data (Start async)
 * Async action to start fetching candidate data from the server
 * @see finishGetCandidateData()
 */
const startGetCandidateData = () => createAction(GetCandidateDataStartType, {});

/** 
 * Action: Get Candidate Data (Start async)
 * Async action to start fetching candidate data from the server
 */
const startRefreshCandidateData = () => createAction(RefreshCandidateDataType, {});

/** 
 * Action: Refresh Candidate Data (Finish async)
 * Async action to finish fetching candidate data from the server
 */
const finishRefreshCandidateData = (results: CandidateModel) => createAction(RefreshCandidateFinishType, results);

/**
 * Action: Set preview candidate
 * @param candidate 
 */
export const setPreviewCandidateData = (candidate: CandidateModel) => createAction(SetPreviewCandidateType, candidate);

/** 
 * Action: Get Candidate Data (Finish async)
 * Async action resulting from a fetch request that contains the candidate data from the server
 * @param results The CandidateModel returned from the server
 * @see startGetCandidateData()
 */
const finishGetCandidateData = (results: CandidateModel) => {
    if (results && results.personal) {
        storeCandidateId(results.personal.id);
    }

    return createAction(GetCandidateDataFinishType, results);
};

/**
 * Action: Get Candidate Data (Failed async)
 * Async action resulting from a fetch request for candidate that failed.
 * @param error The ResponseError returned from the server
 */
const failedGetCandidateData = (error: ResponseError) => createAction(GetCandidateDataErrorType, error);

/**
 * Action: Get Candidate Data
 * Returns a thunk for async fetching of candidate data and populating the store when data is received
 * or stores an error if the request failed
 */
export const getCandidateData = (): ThunkAction<Promise<any>, State.All, unknown, AnyAction> => {
    return (dispatch, getState) => {
        dispatch(startGetCandidateData());
        return getData()
            .then(data => {
                dispatch(finishGetBranding(data.brandingStyles));

                if (IsLanguageChangeNeeded(getState(), data)) {
                    dispatch<any>(Actions.setLanguageId(data.state.languageId));
                }

                dispatch(finishGetCandidateData(data));
            })
            .catch((error: ResponseError) => dispatch(failedGetCandidateData(error)));
    };
};

/**
 * @returns: true - if the site current language differs from the candidates preferred language or
 *                  preffered language differs with language in application state (after F5)
 *           false - otherwise
 */
function IsLanguageChangeNeeded(applicationState: State.All, candidateModel: CandidateModel): boolean {
    return (candidateModel && candidateModel.state &&
        (candidateModel.state.languageId !== CmsService.GetSiteLanguage() ||
        applicationState && applicationState.language && applicationState.language.languageId !== candidateModel.state.languageId));
}

/**
 * Action: Refresh Candidate Data
 * Returns a thunk for async fetching of candidate data and populating the store when data is received
 * or stores an error if the request failed
 */
export const refreshCandidateData = (): ThunkAction<Promise<RefreshCandidateDataFinishAction | GetCandidateDataErrorAction>, State.All, unknown, AnyAction> => {
    return (dispatch, getState) => {
        dispatch(startRefreshCandidateData());
        return getData()
            .then(data => dispatch(finishRefreshCandidateData(data)))
            .catch((error: ResponseError) => dispatch(failedGetCandidateData(error)));
    };
};

/**
 * Gets preview candidate and branding styles for preview.
 * @param brandingName 
 */
export const GetPreviewCandidate = (brandingName: string): ThunkAction<Promise<any>, State.All, unknown, AnyAction> => {
    return (dispatch, getState) => {
        return BrandingService.GetBranding(brandingName, true)
            .then(r => {
                GetDataPreviewCandidate(brandingName)
                    .then(j => {
                        dispatch(finishGetBranding(r));
                        j.brandingStyles = r;
                        dispatch(setPreviewCandidateData(j));
                    }).catch(e => {
                        logError('Error getting preview candidate', e);
                    });

            }).catch(e => {
                logError('Error getting preview branding', e);
            });
    };
};

export type Candidate =
    GetCandidateDataStartAction
    | GetCandidateDataFinishAction
    | GetCandidateDataErrorAction
    | RefreshCandidateDataStartAction
    | RefreshCandidateDataFinishAction
    | SetPreviewCandidateAction;