import React from 'react';
import { NoJsxElement, NullableElement } from '../../misc/common';
import { CmsText } from '../../misc/cms-text';
import * as Containers from '../redux-containers';
import { DispatchProp } from 'react-redux';
import SuccessProfileNotPublishedMessage from './successProfileNotPublishedMessage';

export interface ReportsPreparingStateProps {
    /**
     * Component Text
     */
    text: CmsText;

    /**
     * If reports are being prepared but not yet available for download
     */
    reportsPreparing: boolean;

       /**
    * Success profile publication status
    */
       successProfilePublished?: boolean;
}

type ReportsPreparingAllProps = ReportsPreparingStateProps & DispatchProp<any>;

/**
 * Renders a component that displays a message if there are reports being prepared on the server that
 * aren't yet ready to download
 * @param props The properties to pass to the component
 */
export class ReportsPreparing extends React.Component<ReportsPreparingAllProps> {
    constructor(props: ReportsPreparingAllProps) {
        super(props);
    }

    render(): NullableElement {
        if (!this.props.reportsPreparing) {
            return NoJsxElement;
        }
        if (this.props.reportsPreparing && !this.props.successProfilePublished) {
            return <SuccessProfileNotPublishedMessage />;
          }

        return (
            <div className="reports-preparing-container alert alert-primary d-flex align-items-center">
                <span className="reports-preparing-icon" />
                <span className="reports-preparing-message">
                    {this.props.text.get('reportsPreparing', 'Your feedback reports are currently generating, they will appear below when available for download')}
                </span>
            </div>
        );
    }
}

export default Containers.createStateOnly<ReportsPreparingStateProps>(
    ReportsPreparing,
    (state) => ({
        text: new CmsText(state.language.alltext, 'DownloadReport', 'dashboard.downloadReport'),
        reportsPreparing: state.candidate.reportsPreparing,
        successProfilePublished: state.candidate.successProfilePublished,
    })
);