// @ts-nocheck

import * as State from '../reducers/state';
import { connect, DispatchProp } from 'react-redux';
import { Dispatch } from 'redux';

/**
 * Represents a component designed for Redux containers.
 * 
 * This is actually a React component that has no props because Redux containers are
 * designed to fetch state from the Redux store and pass that as props down to the actual
 * component within the container.
 * 
 * @example const MyContainer: ContainerComponent = connect(mapStateToProps)(MyComponent)
 */
export type ContainerComponent = React.ComponentClass<{}>;

/**
 * Type alias for a React component that is intended to only hold Redux state.
 * 
 * The component can either be a Component class or a Stateless component.
 */
export type StateOnlyComponentTarget<S> =
    React.ComponentClass<S & DispatchProp<any>>         /* tslint:disable-line:no-any */
    | React.FunctionComponent<S & DispatchProp<any>>;  /* tslint:disable-line:no-any */

/**
 * Type alias for a React component that is intended to only hold Redux state.
 * 
 * The component can either be a Component class or a Stateless component.
 */
export type StatePropsOnlyComponentTarget<S, P> =
    React.ComponentClass<S & P & DispatchProp<any>>         /* tslint:disable-line:no-any */
    | React.FunctionComponent<S & P & DispatchProp<any>>;  /* tslint:disable-line:no-any */

/**
 * Type alias for a React component that is intended to hold Redux state and dispatch events.
 * 
 * The component can either be a Component class or a Stateless component.
 */
export type StateDispatchComponentTarget<S, D> =
    React.ComponentClass<S & D>
    | React.FunctionComponent<S & D>;

/**
 * Type alias for a React component that is intended to hold Redux state and dispatch events.
 * 
 * The component can either be a Component class or a Stateless component.
 */
export type StateDispatchPropsComponentTarget<S, D, P> =
    React.ComponentClass<S & D & P>
    | React.FunctionComponent<S & D & P>;

/**
 * Create a Redux container using the provided component and state mapping function.
 * @param Component The Component to wrap into the container.
 * @param mapStateToProps The function that maps Redux state to the props for the component
 * @returns A new component that has no props, since the props will be populated from Redux instead
 */
export function createStateOnly<StateProps>(
    Component: StateOnlyComponentTarget<StateProps>, 
    mapStateToProps: (state: State.All) => StateProps)
    : ContainerComponent {
    return connect(mapStateToProps)(Component);
}

/**
 * Create a Redux container using the provided component and state mapping function with the component containing it's own properties
 * @param Component The Component to wrap into the container.
 * @param mapStateToProps The function that maps Redux state to the props for the component
 * @returns A new component that has no props, since the props will be populated from Redux instead
 */
export function createStateWithProps<StateProps, OwnProps>(
    Component: StatePropsOnlyComponentTarget<StateProps, OwnProps>,
    mapStateToProps: (state: State.All, props: OwnProps) => StateProps)
    : React.ComponentClass<OwnProps> {
    return connect<StateProps, null, OwnProps>(mapStateToProps)(Component);
}

/**
 * Create a Redux container using the provided component and state/dispatch mapping functions.
 * @param Component The Component to wrap into the container.
 * @param mapStateToProps The function that maps Redux state to the props for the component
 * @param mapDispatchToProps The function that maps Redux dispatch to the props for the component
 * @returns A new component that has no props, since the props will be populated from Redux instead
 */
export function createStateAndDispatch<StateProps, DispatchProps>(
    Component: StateDispatchComponentTarget<StateProps, DispatchProps>,
    mapStateToProps: (state: State.All) => StateProps,
    mapDispatchToProps: (dispatch: Dispatch<any>) => DispatchProps)
    : ContainerComponent {
    return connect(mapStateToProps, mapDispatchToProps)(Component);
}

/**
 * Create a Redux container using the provided component and state/dispatch mapping functions.
 * @param Component The Component to wrap into the container.
 * @param mapStateToProps The function that maps Redux state to the props for the component
 * @param mapDispatchToProps The function that maps Redux dispatch to the props for the component
 * @returns A new component that has no props, since the props will be populated from Redux instead
 */
export function createStateAndDispatchWithProps<StateProps, DispatchProps, OwnProps>(
    Component: StateDispatchPropsComponentTarget<StateProps, DispatchProps, OwnProps>,
    mapStateToProps: (state: State.All, props: OwnProps) => StateProps,
    mapDispatchToProps: (dispatch: Dispatch<any>) => DispatchProps)
    : React.ComponentClass<OwnProps> {
    return connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(Component);
}