import { LaunchAssessmentResult, AssessmentReuseRequest, AssessmentReuseResult } from '../models/assessment';
import { CandidateModel, CandidateUpdateModel } from '../models/candidate';
import { getObject, postObject } from './api-service';

/**
 * Gets all candidate data for the current authenticated candidate.
 * @returns A CandidateModel that contains all current information on the server.
 */
export const getData = (): Promise<CandidateModel> => getObject('candidate', (json) => <CandidateModel> json);

/**
 * Updates the candidate preferred language after the site language has changed.
 * @param languageId The new preferred language
 */
export const updateCandidateLanguage = (languageId: number) => postObject<CandidateUpdateModel, string>('candidate', { languageId: languageId }, (result) => '');

/**
 * Gets the launch url to start a Reflex assessment by redirecting the browser to the provided url.
 * @param assessmentId The id of the assessment to launch.
 * @param language The language of the assessment.
 */
export const getLaunchAssessmentUrl = (assessmentId: number, languageId: number) =>
    getObject(
        'assessment/',
        (json) => <LaunchAssessmentResult> json,
        { id: assessmentId, language: languageId });

export const reuseAssessment = (assessmentId: number, reuseAssessmentId: number) =>
    postObject<AssessmentReuseRequest, AssessmentReuseResult>(
        'AssessmentReuse',
        { assessmentId: assessmentId, reuseAssessmentId: reuseAssessmentId },
        (result) => <AssessmentReuseResult> result);

/**
 * Gets candidate data for preview, data is stored locally within json file (misc/PreviewCandidate.json') 
 * @export
 * @returns {Promise<CandidateModel>} 
 */
export function GetDataPreviewCandidate(brandingName: string): Promise<CandidateModel> {
    return new Promise(function (resolve: any, reject: any) {
        try {
            let candidate: CandidateModel = require('../misc/PreviewCandidate.json');
            candidate.brandingStyles.name = brandingName;
            return resolve(candidate);
        } catch (e) {
            reject(e);
        }
    });
}