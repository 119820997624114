import React from 'react';
import { NoJsxElement, NullableElement } from '../../misc/common';
import { CmsText } from '../../misc/cms-text';
import * as Containers from '../redux-containers';
import { DispatchProp } from 'react-redux';
import { eLearningModel } from '../../models/elearning';
import ELearningContent from './eLearningContent';

export interface ELearningContentsStateProps {
    /**
     * Component Text
     */
    text: CmsText;

    /**
     * All eLearning contents for a candidate
     */
    eLearningContent?: eLearningModel[];
}

type ELearningContentsAllProps = ELearningContentsStateProps & DispatchProp<any>;

/**
 * Renders an eLearning content links component that opens a new windows to display eLearning content from KF Learn.
 * @param props The properties to pass to the component
 */
export class ELearningContents extends React.Component<ELearningContentsAllProps> {
    constructor(props: ELearningContentsAllProps) {
        super(props);
    }

    render(): NullableElement {
        if (!this.props.eLearningContent || this.props.eLearningContent!.length === 0) {
            return NoJsxElement;
        }

        return (
            <div>
                <div className="eLearning-contents-container">
                    <div className="eLearning-contents-heading">{this.props.text.get('heading', 'KF Learn')}</div>

                    {this.props.eLearningContent!.map((l) => {
                        return (<ELearningContent content={l} key={l.contentId} />);
                    })}
                </div>
                <div className="eLearning-contents-divider" />
            </div>
        );
    }
}

export default Containers.createStateOnly<ELearningContentsStateProps>(
    ELearningContents,
    (state) => ({
        text: new CmsText(state.language.alltext, 'eLearnings', 'dashboard.eLearning'),
        eLearningContent: state.candidate.eLearnings
    })
);