import { ResponseError } from '../../services/api-service';

/**
 * Component properties for the DevErrorView
 */
export type DevErrorViewProps = {
    /**
     * The main message to display in the view
     */
    message: string,

    /**
     * Optional server error response to render if available
     */
    errorResponse?: ResponseError,

    /**
     * Url for logging back into the system
     */
    loginUrl?: string
};

/**
 * Development only component to render an error message with details.
 * @param props The properties to use for rendering
 */
export const DevErrorView = (props: DevErrorViewProps) => {
    let httpError: JSX.Element | null = null;
    if (props.errorResponse !== undefined) {
        httpError = (
            <div>
                <h2>HTTP Error Response</h2>
                <div>
                    Status Code: {props.errorResponse.status}
                </div>
                <div>
                    Message: {props.errorResponse.message}
                </div>
            </div>
        );
    }

    let loginUrl: JSX.Element | null = null;
    if (props.loginUrl !== undefined) {
        loginUrl = <a href={props.loginUrl}>Log back in</a>;
    }

    return (
        <div>
            <h1> Error Occurred</h1>
            <div>
                {props.message}
            </div>   
            {httpError}      
            {loginUrl}
        </div>
    );
};

export default DevErrorView;