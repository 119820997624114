import 'isomorphic-fetch';
import { getObject, buildActionUrlFromParts } from './api-service';
import { BrandingResult } from '../models/branding';

/* Service to get branding for a client */
export namespace BrandingService {
    const brandingStylesEndPoint = 'branding/styles';
    const brandingStylesFullEndPoint = buildActionUrlFromParts(process.env.REACT_APP_CDN_API_URL, process.env.REACT_APP_API_VERSION, brandingStylesEndPoint);

    export function GetBranding(branding: string, isPreview: boolean = false): Promise<BrandingResult> {
        if (branding) {
            let params: any = {
                name: branding,
                isPreview: String(isPreview)
            };

            if (isPreview) {
                // bypass the CDN for preview as we always want the latest version
                return getObject('branding/styles', (json) => <BrandingResult> json, params);
            }

            // We need to get the version from the server so that if the branding gets updated, CloudFront
            // will get the new version and cache it

            // create a new promise as we want the result of the branding request, not the version result
            return new Promise<BrandingResult>((resolve, reject) => {
                getObject('branding/version', (json) => <BrandingResult> json, params)
                    .then(j => {
                        params.version = j.version;
                        getObject(brandingStylesFullEndPoint, (json) => <BrandingResult> json, params)
                            .then(m => resolve(m))
                            .catch(error => reject(error));
                    })
                    .catch(error => reject(error));
            });
        }

        return Promise.resolve({
            version: '',
            errorText: '',
            styles: '',
            name: '',
            logoUrl: '',
            hasDashboardManifest: false
        });
    }
}