import { Action, createAction } from './actions';
import { AssessmentModel } from '../models/assessment';

export const ReuseAssessmentShowType = 'REUSE_ASSESSMENT_DIALOG_SHOW';
export type ReuseAssessmentShowAction = Action<typeof ReuseAssessmentShowType, { show: boolean, assessment: AssessmentModel | null }>;

export function showReuseAssessment(show: boolean, assessment: AssessmentModel | null = null): ReuseAssessmentShowAction {
    return createAction(ReuseAssessmentShowType, { show: show, assessment: assessment });
}

export type AssessmentReuse =
    ReuseAssessmentShowAction;