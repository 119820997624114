import { CmsText } from '../../misc/cms-text';
import * as Containers from '../redux-containers';
import format from 'string-template';
import parse, { attributesToProps } from 'html-react-parser';

/**
 * State props for the Footer component.
 */
export interface FooterStateProps {
    text: CmsText;
}

/**
 * Footer component
 */
export function Footer(props: FooterStateProps) {
    const ariaLabelText = (text: string) => {
      return props.text.getAccessibilityText(text);
    };

    // To add aria-label attribute to the parsed anchor element
    const options = {
      replace(domNode: any) {
        if (domNode.attribs && domNode.name === "a") {
          domNode.attribs["aria-label"] = ariaLabelText(
            domNode.children[0]?.data.toLowerCase()
          );
          const anchorText = domNode.children[0]?.data || "";
          const props = attributesToProps(domNode.attribs);
          return <a {...props}> {anchorText} </a>;
        }
        return domNode;
      },
    };
    return (
        <footer className="footer">
            <div className="brandbar" />
            <div className="footer-bar">
                <div className="copyright">
                    <a><img src="/images/logo-kornferryFooter.svg" alt="Korn Ferry" /></a>
                    <p>{format(props.text.get('copyRight', '© Korn Ferry {currentyear} All Rights Reserved.'), { 'currentyear': new Date().getFullYear().toString()})}</p>
                </div>
                <div className="links-right">
                <nav aria-label="Footer Links">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            {parse(props.text.get('terms', 'terms'), options)}
                        </li>
                        <li className="list-inline-item">
                            {parse(props.text.get('privacy', 'Privacy'), options)}
                        </li>
                    </ul>
                </nav>
                </div>
            </div>
        </footer>
    );
}

export default Containers.createStateOnly(
    Footer,
    (state) => ({
        text: new CmsText(state.language.alltext, 'Footer', 'footer')
    })
);
