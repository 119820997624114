import { CmsText } from '../../misc/cms-text';
import Footer from '../footer/footer';
import * as Containers from '../redux-containers';
import HeaderContainer from '../header/header';

/**
 * State props for the Sanctioned component.
 */
export interface SanctionedStateProps {
    text: CmsText;
}

/**
 * Sanctioned component
 */
export function Sanctioned(props: SanctionedStateProps) {
    return (
        <div>

            <div className="main-layout">
                <div className="main-layout-header">
                    <HeaderContainer showHumburgerMenuIcon={false} showLanguageSelectDropdown={true}/>
                </div>
                <main className="main-layout-content">
                    <div className="centered-layout">
                        <p className="signinpanel-statement">{
                            props.text.get('embargoedCountriesSanctionsText', 'Unfortunately, due to current sanctions, Korn Ferry cannot provide services to any individuals located in Cuba, Iran, North Korea, Russia, Syria.')
                        }</p>
                    </div>
                </main>
                <div className="main-layout-footer">
                    <Footer />
                </div>
            </div>

        </div>
    );
}

export default Containers.createStateOnly(
    Sanctioned,
    (state) => ({
        text: new CmsText(state.language.alltext, 'Sanctioned', 'dashboard.sanctions')
    })
);
