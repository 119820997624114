import { Action, createAction } from './actions';
import { BrandingResult } from '../models/branding';
import { storeBranding } from '../misc/brandingSession';

export const GetBrandingFinishType = 'GET_BRANDING_FINISH';
export type GetBrandingFinishAction = Action<typeof GetBrandingFinishType, BrandingResult>;

/**
 * Add branding styles to store
 * 
 * @export
 * @param {BrandingResult} branding 
 * @returns {GetBrandingFinishAction} 
 */
export function finishGetBranding(branding: BrandingResult): GetBrandingFinishAction {
    if (branding.styles === null) {
        branding.styles = ' ';
    }
    storeBranding(branding);
    return createAction(GetBrandingFinishType, branding);
}

// Export all of the actions from this file.
export type BrandingActions = GetBrandingFinishAction;