import React from 'react';
import { Loading } from './loading/loading';

/**
 * Async props
 */
export interface AsyncButtonProps {
    /**
     * The disabled state of the button
     */
    disabled?: boolean;

    /**
     * Click event 
     */
    clickEvent: () => Promise<any>;

    /**
     * Class to be used for button.
     */
    className?: string;

    /**
     * Text for aria label
     */
    ariaLabelText?: string;

    /**
     * React children
     */
    children?: React.ReactNode;
}

export interface AsyncButtonState {
    /**
     * If waiting for the event then display the spinner.
     */
    isWaiting: boolean;
}

/**
 * Generic asynchronous button component, that displays a spinner while the event happens.
 */
export class AsyncButton extends React.Component<AsyncButtonProps, AsyncButtonState> {
    constructor(props: AsyncButtonProps) {
        super(props);
        this.state = {
            isWaiting: false
        };
    }

    click() {
        this.setState({ isWaiting: true });

        this.props.clickEvent()
            .then(result => this.setState({ isWaiting: false }))
            .catch(exception => {
                this.setState({ isWaiting: false });
                throw (exception);
            });
    }

    render() {
        // Doing something show the spinner
        if (this.state.isWaiting) {
            return <Loading fullScreen={false} />;
        }

        let ariaLabel = {}, ariaDescription = {};

        if (this.props.ariaLabelText) {
            ariaLabel = {
                'aria-label': this.props.ariaLabelText
            };
        }

  

        return (
            <button 
                onClick={() => this.click()} 
                className={this.props.className} {...ariaLabel}  {...ariaDescription}
                disabled={this.props.disabled}>
                {this.props.children}
            </button>
        );
    }
}
