import classNames from 'classnames';

/**
 * Step progress bar state props
 */
export interface StepProgressBarProps {
    /**
     * The maximum number of steps to display.
     */
    maxSteps: number;

    /**
     * The current step within the progress.
     */
    currentStep: number;
}

/**
 * Generic Progress bar shown in step increments. The styling for each step is added as an additional style.
 */
export default function StepProgressBar(props: StepProgressBarProps) {
    let steps: JSX.Element[] = [];
    
    for (let i = 1; i <= props.maxSteps; i++) {
        const classes = classNames({
            'tile': true,
            ["tile-" + props.maxSteps]: true,
            'complete': i <= props.currentStep
        });
        steps.push(
            <span 
                key={i} 
                data-testid={(i <= props.currentStep) ? `progress-step-complete` : `progress-step` } 
                className={classes} />
        );
    }

    return (
        <div className="dashboard-progress-bar" data-testid="dashboard-progress-bar">
            {steps}
        </div>
    );
}