import { JL } from 'jsnlog';
import { makeGetUrl } from '../services/api-service';
import { getCandidateId } from '../misc/session';

/**
 * Setting for jsnlog ajax appender
 * storeInBufferLevel: If the severity of the log message is equal or greater than this, but smaller than level, the log message will not be sent to the server, but stored in an internal buffer.
 * If bufferSize is 0 or less, the log message is simply ignored.
 * sendWithBufferLevel: If the severity of a log message is equal or greater than this, not only the log message but also all log messages stored in the internal buffer will be sent to the server.
 * This allows you to store low priority trace messages in the internal buffer, and only send them when a high priority fatal message is sent.
 * bufferSize: Sets the size of the buffer used with sendWithBufferLevel and storeInBufferLevel.
 * batchSize: Allows you to improve performance by sending multiple log messages in one go, rather than one by one.
 * LogLevels: TRACE: 1000 , DEBUG: 2000, INFO: 3000, WARN: 4000, ERROR: 5000, FATAL: 6000
 */
const ajaxAppenderOptions = {
    level: process.env.REACT_APP_Log_Level,
    storeInBufferLevel: 4000,
    sendWithBufferLevel: 4000,
    bufferSize: 2,
    batchSize: 1
};

/**
 * Settings for console appender ( log messages to console , currently console logging is enabled for local development)
 */
const consoleAppenderOptions = ({
    level: 2000,
    batchSize: 1
});

/**
 * Logs the error with  server.
 * @param logObject 
 */
export const logError = (logObject: any): void => {
    getLogger().error(logObject);
};

/**
 * Logs warning message with server
 * @param logObject
 */
export const logWarning = (logObject: any): void => {
    getLogger().warn(logObject);
};

/**
 * Logs fatal exception with server
 * @param logObject 
 * @param exception 
 */
export const logFatal = (logObject: any, exception: any): void => {
    getLogger().fatalException(logObject, exception);
};

/**
 * Logs info level message with server
 * @param logObject 
 */
export const logInfo = (logObject: any): void => {
    getLogger().info(logObject);
};

/**
 * Logs debug level message with server
 * @param logObject 
 */
export const logDebug = (logObject: any): void => {
    getLogger().debug(logObject);
};

export const logTrace = (logObject: any): void => {
    getLogger().trace(logObject);
};

const getLogger = (): JL.JSNLogLogger => {

    /**
     * Settings  for jsnlog
     * enabled: logger log to server when this is set to true
     * maxMessages: Maximum number of logs that can be sent to server per page
     * defaultAjaxUrl: Url of rest api that is called to log message on server.
     * clientIP: Client's Ip address ( currently not set).
     * requestId: Identifies the request for which the log message was created.
     */
    let loggerOptions = {
        enabled: process.env.REACT_APP_Logger_ENABLED,
        maxMessages: process.env.REACT_APP_Logger_MAX_MESSAGES,
        defaultAjaxUrl: makeGetUrl('ClientLogger'),
        requestId: getCandidateId()!
    };

    let localEnvironment: boolean = process.env.REACT_APP_ENV === 'local';
    let consoleAppender: JL.JSNLogConsoleAppender = JL.createConsoleAppender('consoleAppender');
    let ajaxAppender = JL.createAjaxAppender('ajaxAppender');

    JL.setOptions(loggerOptions);
    ajaxAppender.setOptions(ajaxAppenderOptions);
    if (localEnvironment) {
        consoleAppender.setOptions(consoleAppenderOptions);
    }

    let logger = JL('Dashboard site');
    logger.setOptions({
        appenders: localEnvironment && [ajaxAppender, consoleAppender] || [ajaxAppender]
    });
    return logger;
};